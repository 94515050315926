<script setup lang="ts">
import { computed, ref, defineProps, withDefaults } from "vue";

export interface NavbarDropdownProps {
  label: string;
  boxed?: boolean;
}

const props = withDefaults(defineProps<NavbarDropdownProps>(), {
  boxed: false,
});

const openDropDown = ref(false);
const dropdownClasses = computed(() => [
  props.boxed && `is-boxed`,
  openDropDown.value && `open`,
]);
</script>

<template>
  <div class="navbar-item has-dropdown is-hoverable">
    <a class="navbar-link is-arrowless" @click="openDropDown = !openDropDown">
      <span>{{ $t("language") }}</span>
      <i class="iconify mx-1 fa fa-angle-down" :class="[openDropDown&& 'open']"></i>
    </a>

    <div class="navbar-dropdown" :class="dropdownClasses">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.navbar-item {
  &.has-dropdown {
    .navbar-link {
      color: var(--navbar-link-color-dark);

      .iconify {
        margin-left: 0.25rem;
        transition: transform 0.3s;
      }

      &:hover {
        .iconify {
          transform: rotate(180deg);
        }
      }
    }

    .navbar-dropdown {
      background: var(--navbar-bg-color);
      border: 1px solid var(--navbar-border-color);
      box-shadow: var(--light-box-shadow);
      min-width: 210px;
      padding: 0.75rem 0;
      :deep(.navbar-item) {
        margin: 0 0.75rem;
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        transition: background-color 0.3s;
        color: var(--navbar-link-color-medium);

        &:hover {
          background: var(--navbar-dropdown-item-hover-bg) !important;
          color: var(--primary);
        }
      }

      :deep(.navbar-divider) {
        background: var(--navbar-border-color);
      }

      &.is-boxed {
        border-radius: 0.75rem;
      }
    }
  }
}

.is-inverted {
  .navbar-item {
    &.has-dropdown {
      .navbar-link {
        color: var(--navbar-link-color-inverted);
      }
    }
  }
}

.is-scrolled,
.is-solid {
  .navbar-item {
    &.has-dropdown {
      .navbar-link {
        color: var(--navbar-link-color-medium);
      }
    }
  }
}

@media (max-width: 767px) {
  .navbar-dropdown {
    display: none !important;
  }
  .navbar-dropdown.open {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .navbar-item.has-dropdown .navbar-link:hover .iconify{
    transform: rotate(0deg);
  }
  .navbar-item.has-dropdown .navbar-link:hover .iconify.open{
    transform: rotate(180deg);
  }
  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  .navbar-link.is-active {
    background: none !important;
  }
  .navbar-item{
    .navbar-link{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .navbar-item {
    &.has-dropdown {
      .navbar-link {
        display: none;
      }

      .navbar-dropdown {
        border: none;
        box-shadow: none;
        padding-top: 0;

        :deep(.navbar-item) {
          font-size: 1rem;

          &.is-flex {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
</style>

// @media only screen and (max-width: 767px) { // .navbar-item { //
&.has-dropdown { // .navbar-link { // display: none; // } // .navbar-dropdown {
// border: none; // box-shadow: none; // padding-top: 0; // :deep(.navbar-item)
{ // font-size: 1rem; // &.is-flex { // margin-bottom: 0.5rem; // } // } // } //
} // } // }
