<template>
<div class="iter-Admin">
  <LaptopApp/>
  <ScreenshotsAreaApp/>
  <SwiperRightAdminApp :title="title"/>
  <SwiperLeftAdmin :title="title"/>
  <PricingApp/>
  <FAQApp/>
  <WhyisbestApp/>
</div>
</template>

<script setup>
import { ref } from "vue";
import ScreenshotsAreaApp from "@/components/sections/ScreenshotsArea";
import PricingApp from "@/components/sections/PricingApp";
import FAQApp from "@/components/sections/FAQ";
import WhyisbestApp from "@/components/sections/WhyIsBest";
import LaptopApp from "@/components/sections/LaptopApp";
import SwiperRightAdminApp from "@/components/sections/SwiperRightAdmin";
import SwiperLeftAdmin from "@/components/sections/SwiperLeftAdmin";

      const title = ref("ITER Admin");
// const description = ref('Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.')
</script>

<style scoped>

</style>