<template>
  <div class="progress-wrap" @click="scrollTop">
    <svg
      class="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
</template>

<script setup>
import {ref,onMounted} from "vue";
      const progressPath= ref(null)
      const pathLength =ref(null)
      const offset=ref(20)
      // const duration= ref(20)
 

    window.addEventListener('scroll', updateProgress); 
    //Scroll back to top
    onMounted(()=>{
    progressPath.value = document.querySelector('.progress-wrap path');
    pathLength.value = progressPath.value.getTotalLength();
    progressPath.value.style.transition = progressPath.value.style.WebkitTransition = 'none';
    progressPath.value.style.strokeDasharray = pathLength.value + ' ' + pathLength.value;
    progressPath.value.style.strokeDashoffset = pathLength.value;
    progressPath.value.getBoundingClientRect();
    progressPath.value.style.transition = progressPath.value.style.WebkitTransition = 'stroke-dashoffset 10ms linear';
    updateProgress;
  })

   function documentHeight(){
      var body = document.body;
    var html = document.documentElement;

      return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    }
     function updateProgress() {
      var scroll = window.pageYOffset; 
      var height = documentHeight() - window.innerHeight;

      var progress = pathLength.value - (scroll * pathLength.value / height);
      progressPath.value.style.strokeDashoffset = progress;

      if (window.pageYOffset > offset.value) {
        document.querySelector('.progress-wrap').classList.add('active-progress');
      } else {
        document.querySelector('.progress-wrap').classList.remove('active-progress');
      }
    }
    function scrollTop(){
      window.scrollTo(
        {
          top:0,
          behavior:'smooth',
          
        }
      )
    }
 

</script>

<style scoped>
.progress-wrap:lang(en) {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:lang(ar) {
  position: fixed;
  left: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap::after {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  content: "\f106";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  color: var(--main-color);
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  font-weight: 900;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::after {
  opacity: 0.5;
}
.progress-wrap::before {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  content: "\f106";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  opacity: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  font-weight: 900;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::before {
  opacity: 1;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: var(--main-color);
  stroke-width: 5;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

/* End Progress to back up
================================================== */
</style>
