<template>
<div class="job">
  <section class="banner-area"  >
    <img class="position-absolute w-100 h-100" src="@/assets/images/banners/banner.jpg">
    <div class="overlay-bg"></div>
    <div class="myContainer">
      <div class="row ">
        <div class="col-lg-12">
          <h1 class="text-white">
            Over <span>100+</span> jobs are waiting for you
          </h1>
          <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="maintenance">
      <h1 >Maintenance Underway</h1>
    </div>

  </section>


</div>
</template>

<script setup>
</script>

<style scoped>

.banner-area {
  background-size: contain;
  height: 550px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.overlay-bg{
  position: absolute;
  background-image: -moz-linear-gradient(0deg, #000000c2 0%, #080808b0 100%);
  background-image: -webkit-linear-gradient(0deg, #000000c2 0%, #080808b0 100%);
  background-image: -ms-linear-gradient(0deg, #000000c2 0%, #080808b0 100%);
  background-image: linear-gradient(0deg, #000000c2 0%, #080808b0 100%);
  height: 100%;
  width: 100%;
  top: 0;

}
.banner-area span{
  color: var(--main-color);
}

.maintenance {
  height: 800px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


</style>