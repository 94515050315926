<template>
<section id="questions">

    <div class="myContainer">
      <div class="row" data-aos="fade-up">
        <div class="col-12">
          <!-- Heading Text -->
          <div class="section-heading">
            <h2>Questions</h2>
          </div>
        </div>
      </div>
      <div class="row" >

        <div class="col-lg-4 col-md-6 col-12">
          <div class="card mb-3" >
            <div class="row no-gutters">
              <div class="col-md-4">
                <img src="@/assets/images/bg-img/app111.png" class="card-img" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Question title ?</h5>
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="card mb-3" >
            <div class="row no-gutters">
              <div class="col-md-4">
                <img src="@/assets/images/bg-img/app222.png" class="card-img" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Question title ?</h5>
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="card mb-3" >
            <div class="row no-gutters">
              <div class="col-md-4">
                <img src="@/assets/images/bg-img/app333.png" class="card-img" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Question title ?</h5>
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-12">
          <div class="card mb-3" >
            <div class="row no-gutters">
              <div class="col-md-4">
                <img src="@/assets/images/bg-img/app111.png" class="card-img" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Question title ?</h5>
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="card mb-3" >
            <div class="row no-gutters">
              <div class="col-md-4">
                <img src="@/assets/images/bg-img/app222.png" class="card-img" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Question title ?</h5>
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="card mb-3" >
            <div class="row no-gutters">
              <div class="col-md-4">
                <img src="@/assets/images/bg-img/app333.png" class="card-img" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Question title ?</h5>
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>



</section>
</template>

<script setup>

</script>

<style scoped>
#questions{
  padding-top: 100px;
  padding-bottom: 15px;
  background: #f5f5f7;
}
.card-img{
  height: 100%;
  width: 100%;
  background-size: cover;
}
.card{
  border: 0;
  border-radius: 16px;
  padding: 16px;
  -webkit-box-shadow: 0 20px 45px rgba(0,0,0,0.09);
  box-shadow:0 20px 45px rgba(0,0,0,0.09);
}
</style>