<template>
  <section class="auth-page position-relative">
    <div class="animation-circle"><i></i><i></i><i></i></div>
    <div class="container cooming-soon text-center">
      <div class="row">
        <div class="col-md-6 mb-3 left">
          <div class="clock-box">
            <div>
              <div class="mb-4">
                <h2 class="title-Soon">We Are Launching Soon</h2>
              </div>
              <div id="clockdiv">
                <div class="mr-2 mb-2">
                  <span class="days">{{ this.Days }}</span>
                  <div class="smalltext">Days</div>
                </div>
                <div class="mr-2 mb-2">
                  <span class="hours">{{ this.Hours }}</span>
                  <div class="smalltext">Hours</div>
                </div>
                <div class="mr-2 mb-2">
                  <span class="minutes">{{ this.Minutes }}</span>
                  <div class="smalltext">Minutes</div>
                </div>
                <div class="mr-2 mb-2">
                  <span class="seconds">{{ this.Seconds }}</span>
                  <div class="smalltext">Seconds</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="home-right">
            <div class="animation-circle-inverse"><i></i><i></i><i></i></div>
            <div class="mobile-slid">
              <img
                src="@/assets/images/scr-img/app-1.png"
                alt="top1"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref,onMounted } from "vue";

     const Seconds =ref(0)
     const Minutes =ref(0)
     const Hours =ref(0)
     const Days =ref(0)

  const  showTime=()=>{
      var endDate=new Date(2023,1,1,0,0,0).getTime();
      const timer=setInterval(()=>{
        var nowDate=new Date().getTime();
        var distance=endDate-nowDate;

        Days.value = Math.floor(distance / (1000 * 60 * 60 * 24));
        Hours.value = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        Minutes.value = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        Seconds.value = Math.floor((distance % (1000 * 60)) / 1000);

        if(distance<=0){
          clearInterval(timer);
        }

      });
    }
    onMounted(() => {
      showTime()
    })
</script>

<style scoped>
.title-Soon {
  text-align: center;
  padding-bottom: 35px;
  position: relative;
  display: inline-block;
  font-weight: 300;
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;
}

.title-Soon::before {
  content: "";
  background: red;
  width: 70px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  margin: auto;
}

.title-Soon:after {
  content: "";
  background: var(--main-color);
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}

.cooming-soon .home-right .mobile-slid {
  position: relative;
}
.cooming-soon .home-right .animation-circle-inverse i {
  top: 30%;
  right: 40%;
  z-index: 0;
}
#clockdiv {
  font-family: sans-serif;
  color: #fff;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
}
#clockdiv > div {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid var(--main-color);
  display: inline-block;
}
#clockdiv div > span {
  padding: 15px;
  border-radius: 3px;
  background: var(--main-color);
  display: inline-block;
}
#clockdiv .smalltext {
  color: #586082;
}
.smalltext {
  padding-top: 5px;
  font-size: 16px;
}
.clock-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.clock-bg {
  background-color: var(--main-color);
  padding: 50px 0 35px;
  position: relative;
}
.social-bg-coming-soon {
  background-color: var(--main-color);
  padding: 15px 0;
}
.auth-form .social-btns button {
  margin: 0 5px;
  border: none;
}
.auth-form .social-btns .fb:hover,
.auth-form .social-btns .ggl:hover {
  color: #fff !important;
}
.auth-form .social-btns .fb {
  background: #3b5998;
}
.auth-form .social-btns .ggl {
  background: #d85040;
}
.auth-form .or-saparator {
  position: relative;
}
.auth-form .or-saparator span {
  background-color: #fff;
  padding: 0 10px;
  z-index: 2;
  position: relative;
}
.auth-form .or-saparator:before {
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  margin-top: 1px;
  width: 100%;
  content: "";
  background: rgba(40, 56, 76, 0.1);
  z-index: 1;
}
.auth-form .forgot-pass {
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 700;
  color: #5f57ea;
  position: absolute;
  border-left: 1px solid #d8d8d8;
  top: 6px;
  right: 5px;
}
.auth-form .form-group {
  position: relative;
  margin-bottom: 20px;
}
.auth-form .form-group:hover label {
  opacity: 1;
  top: -9px;
}
.auth-form .form-group label {
  padding: 0 7px;
  background: #9647db;
  color: #fff;
  border-radius: 3px;
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 9px;
  left: 15px;
  transition: all 0.3s ease 0s;
}
.auth-form .form-group i {
  line-height: 50px;
  color: #586082;
  text-align: center;
  position: absolute;
  font-size: 16px;
  top: -2px;
  left: 20px;
  z-index: 1;
}
.auth-form .form-control {
  height: 45px;
  padding: 10px 52px;
  position: relative;
  transition: all 0.3s ease 0s;
}
.auth-form .form-control:focus {
  border-color: #9647db;
  box-shadow: none;
}
.auth-form textarea {
  height: 100px !important;
}
.auth-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.auth-page .animation-circle i {
  top: 0;
  background: #eee;
}
.auth-page .animation-circle-inverse i,
.auth-page .profile-1 {
  bottom: 0;
}
.mobile-slid {
  text-align: center;
}
.auth-page {
  height: 1000px;
}

.auth-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.auth-page .animation-circle i {
  top: 0;
  background: #eee;
}
.auth-page .animation-circle-inverse i,
.auth-page .profile-1 {
  bottom: 0;
}

.animation-circle-inverse {
  opacity: 0.3;
}
.download-bg .display-flex {
  justify-content: center;
}
.animation-circle-inverse {
  opacity: 0.25;
}
.animation-circle-inverse.absolute i {
  bottom: 280px;
  left: 0;
}

.feature .animation-circle-inverse i {
  top: 52%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.animation-circle-inverse i {
  background: var(--main-color);
  right: 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  opacity: 0.3;
  transform: scale(1.3);
  -webkit-animation: ripple1 3s linear infinite;
  animation: ripple1 3s linear infinite;
}
.animation-circle-inverse i:nth-child(2) {
  -webkit-animation: ripple2 3s linear infinite;
  animation: ripple2 3s linear infinite;
}
.animation-circle-inverse i:nth-child(3) {
  -webkit-animation: ripple3 3s linear infinite;
  animation: ripple3 3s linear infinite;
}

@keyframes ripple1 {
  0% {
    transform: scale(4.5);
    opacity: 0.3;
  }
  to {
    transform: scale(7.5);
    opacity: 0;
  }
}
@-webkit-keyframes ripple1 {
  0% {
    transform: scale(4.5);
    opacity: 0.3;
  }
  to {
    transform: scale(7.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    transform: scale(2.5);
  }
  to {
    transform: scale(4.5);
  }
}
@-webkit-keyframes ripple2 {
  0% {
    transform: scale(2.5);
  }
  to {
    transform: scale(4.5);
  }
}

@keyframes ripple3 {
  0% {
    transform: scale(1.5);
  }
  to {
    transform: scale(2.5);
  }
}
@-webkit-keyframes ripple3 {
  0% {
    transform: scale(1.5);
  }
  to {
    transform: scale(2.5);
  }
}
@media (max-width: 768px) {
  @keyframes ripple1 {
    0% {
      transform: scale(2.5);
      opacity: 0.3;
    }
    to {
      transform: scale(3.8);
      opacity: 0;
    }
  }
  @-webkit-keyframes ripple1 {
    0% {
      transform: scale(2.5);
      opacity: 0.3;
    }
    to {
      transform: scale(3.8);
      opacity: 0;
    }
  }

  @keyframes ripple2 {
    0% {
      transform: scale(1.5);
    }
    to {
      transform: scale(3.8);
    }
  }
  @-webkit-keyframes ripple2 {
    0% {
      transform: scale(1.5);
    }
    to {
      transform: scale(3.8);
    }
  }

  @keyframes ripple3 {
    0% {
      transform: scale(1.5);
    }
    to {
      transform: scale(3.8);
    }
  }
  @-webkit-keyframes ripple3 {
    0% {
      transform: scale(1.5);
    }
    to {
      transform: scale(3.8);
    }
  }
}
@media (max-width: 400px) {
  @keyframes ripple1 {
    0% {
      transform: scale(2.5);
      opacity: 0.3;
    }
    to {
      transform: scale(3);
      opacity: 0;
    }
  }
  @-webkit-keyframes ripple1 {
    0% {
      transform: scale(2.5);
      opacity: 0.3;
    }
    to {
      transform: scale(3);
      opacity: 0;
    }
  }

  @keyframes ripple2 {
    0% {
      transform: scale(1.5);
    }
    to {
      transform: scale(3);
    }
  }
  @-webkit-keyframes ripple2 {
    0% {
      transform: scale(1.5);
    }
    to {
      transform: scale(3);
    }
  }
  @keyframes ripple3 {
    0% {
      transform: scale(1.5);
    }
    to {
      transform: scale(3);
    }
  }
  @-webkit-keyframes ripple3 {
    0% {
      transform: scale(1.5);
    }
    to {
      transform: scale(3);
    }
  }
}
</style>
