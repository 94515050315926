import type { SimpleIconFeature, IconFeature, BaseFeature } from '@/types'

export const mobileAppFeatures: IconFeature[] = [
  {
    title: 'pages.First.home.mobileAppFeatures.Tecnical',
    text: 'pages.First.home.mobileAppFeatures.tex1',
    color: 'primary',
    icon: 'ph:paw-print-duotone',
    link: '/',
  },
  {
    title: 'pages.First.home.mobileAppFeatures.User',
    text: 'pages.First.home.mobileAppFeatures.text2',
    color: 'green',
    icon: 'ph:lightbulb-fill',
    link: '/',
  },
  {
    title: 'pages.First.home.mobileAppFeatures.Private',
    text: 'pages.First.home.mobileAppFeatures.text3',
    color: 'info',
    icon: 'ph:chat-circle-duotone',
    link: '/',
  },
  {
    title: 'pages.First.home.mobileAppFeatures.group',
    text: 'pages.First.home.mobileAppFeatures.text4',
    color: 'purple',
    icon: 'ph:chats',
    link: '/',
  },
  {
    title: 'pages.First.home.mobileAppFeatures.exercises',
    text: 'pages.First.home.mobileAppFeatures.text5',
    color: 'red',
    icon: 'ph:exam',
    link: '/',
  },
  {
    title: 'pages.First.home.mobileAppFeatures.Permissions',
    text: 'pages.First.home.mobileAppFeatures.text6',
    color: 'green',
    icon: 'ph:database-bold',
    link: '/',
  },
  {
    title: 'pages.First.home.mobileAppFeatures.broadcast',
    text: 'pages.First.home.mobileAppFeatures.text7',
    color: 'success',
    icon: 'ph:broadcast',
    link: '/',
  },
  {
    title: 'pages.First.home.mobileAppFeatures.Dashboard',
    text: 'pages.First.home.mobileAppFeatures.text8',
    color: 'orange',
    icon: 'ph:circles-four-duotone',
    link: '/',
  },
]

export const runningFeature: SimpleIconFeature[] = [
  {
    title: 'pages.sadeemTeacher.features.opinion',
    text: 'pages.sadeemTeacher.features.text1',
    icon: 'ph:sparkle-duotone',
  },
  {
    title: 'pages.sadeemTeacher.features.assignments',
    text: 'pages.sadeemTeacher.features.text2',
    icon: 'ph:line-segment',
  },
  {
    title: 'pages.sadeemTeacher.features.tests',
    text: 'pages.sadeemTeacher.features.text3',
    icon: 'ph:lightning-duotone',
  },
  {
    title: 'pages.sadeemTeacher.features.exercises',
    text: 'pages.sadeemTeacher.features.text4',
    icon: 'ph:exam',
  },
]

export const reportFeature: SimpleIconFeature[] = [
  {
    title: 'pages.sadeemTeacher.featuresB.teachers',
    text: 'pages.sadeemTeacher.featuresB.text1',
    icon: 'ph:chart-bar-duotone',
  },
  {
    title: 'pages.sadeemTeacher.featuresB.tracking',
    text: 'pages.sadeemTeacher.featuresB.text2',
    icon: 'ph:clock-duotone',
  },
  {
    title: 'pages.sadeemTeacher.featuresB.report',
    text: 'pages.sadeemTeacher.featuresB.text3',
    icon: 'ph:crown-duotone',
  },
  {
    title: 'pages.sadeemTeacher.featuresB.organization',
    text: 'pages.sadeemTeacher.featuresB.tex4',
    icon: 'ph:star-duotone',
  },
]

export const Student1Feature: SimpleIconFeature[] = [
  {
    title: 'pages.SadeemStudent.Student1Feature.exercises',
    text: 'pages.SadeemStudent.Student1Feature.text1',
    icon: 'ph:sparkle-duotone',
  },
  {
    title: 'pages.SadeemStudent.Student1Feature.chats',
    text: 'pages.SadeemStudent.Student1Feature.text2',
    icon: 'ph:line-segment',
  },
  {
    title: 'pages.SadeemStudent.Student1Feature.schedule',
    text: 'pages.SadeemStudent.Student1Feature.text3',
    icon: 'ph:lightning-duotone',
  },
  {
    title: 'pages.SadeemStudent.Student1Feature.library',
    text: 'pages.SadeemStudent.Student1Feature.text4',
    icon: 'ph:exam',
  },
]
export const Student2Feature: SimpleIconFeature[] = [
  {
    title: 'pages.SadeemStudent.Student2Feature.broadcasts',
    text: 'pages.SadeemStudent.Student2Feature.text1',
    icon: 'ph:chart-bar-duotone',
  },
  {
    title: 'pages.SadeemStudent.Student2Feature.lessons',
    text: 'pages.SadeemStudent.Student2Feature.text2',
    icon: 'ph:clock-duotone',
  },
  {
    title: 'pages.SadeemStudent.Student2Feature.tests',
    text: 'pages.SadeemStudent.Student2Feature.text3',
    icon: 'ph:crown-duotone',
  },
  {
    title: 'pages.SadeemStudent.Student2Feature.search',
    text: 'pages.SadeemStudent.Student2Feature.text4',
    icon: 'ph:star-duotone',
  },
]
export const checkinFeature: SimpleIconFeature[] = [
  {
    title: 'pages.SadeemStudent.Student2Feature.broadcasts',
    text: 'pages.SadeemStudent.Student2Feature.text1',
    icon: 'ph:chart-bar-duotone',
  },
  {
    title: 'pages.SadeemStudent.Student2Feature.lessons',
    text: 'pages.SadeemStudent.Student2Feature.text2',
    icon: 'ph:clock-duotone',
  },
  {
    title: 'pages.SadeemStudent.Student2Feature.tests',
    text: 'pages.SadeemStudent.Student2Feature.text3',
    icon: 'ph:crown-duotone',
  },
  {
    title: 'pages.SadeemStudent.Student2Feature.search',
    text: 'pages.SadeemStudent.Student2Feature.text4',
    icon: 'ph:star-duotone',
  },
]

export const dashboardFeature: BaseFeature[] = [
  {
    title: 'pages.Dashboard.dashboardFeature.Role',
    text: 'pages.Dashboard.dashboardFeature.text1',
  },
  {
    title: 'pages.Dashboard.dashboardFeature.Students',
    text: 'pages.Dashboard.dashboardFeature.text2',
  },
  {
    title: 'pages.Dashboard.dashboardFeature.Employees',
    text: 'pages.Dashboard.dashboardFeature.text3',
  },
]
export const dashboardFeature2: BaseFeature[] = [
  {
    title: '',
    text: '',
  },
  {
    title: '',
    text: '',
  },
  {
    title: '',
    text: '',
  },
]
export const workoutTimeline = [
  {
    icon: 'ph:device-mobile-camera-duotone',
    title: 'Download app ',
    text: 'pages.registration.workoutTimeline.text1',
  },
  {
    icon: 'ph:check-circle',
    title: 'pages.registration.workoutTimeline.username',
    text: 'pages.registration.workoutTimeline.text2',
  },
  {
    icon: 'ph:webcam',
    title: 'pages.registration.workoutTimeline.Login',
    text: 'pages.registration.workoutTimeline.text3',
  },
  {
    icon: 'ph:planet',
    title: 'pages.registration.workoutTimeline.web',
    text: 'pages.registration.workoutTimeline.text4',
  },
]

export const workoutPricingPlans = [
  {
    name: 'Standard',
    icon: 'ph:parachute-duotone',
    price: {
      monthly: 19,
      yearly: 210,
    },
    features: [
      'Premium programs',
      'Social integration',
      'Social network',
      '2h of coaching /month',
      'Live Events',
    ],
    featured: false,
  },
  {
    name: 'Premium',
    icon: 'ph:rocket-duotone',
    price: {
      monthly: 39,
      yearly: 420,
    },
    features: [
      'Custom programs',
      'Live streaming',
      'Personal page',
      '10h of coaching /month',
      'VIP invites',
    ],
    featured: false,
  },
]

export const workoutTestimonials = [
  {
    id: 0,
    name: 'Naya Abed',
    picture: '/assets/p6/image_2022-11-30_083253620-removebg-preview.png',
    cover: 'data:image/gif;base64,replace_with_your_image',
    quote: 'Foundation director',
    subquote:
      '“In my history of working with distance learning platforms, I can honestly say that there is no single company I have ever worked with that offers a better service than Sadeem’s.',
  },
  {
    id: 1,
    name: 'kian raed',
    cover: 'data:image/gif;base64,replace_with_your_image',
    quote: 'Student',
    subquote:
      '"I do not feel anxious about my use of any of Al-Sadeem products. I enjoyed the experience of Sadeem during my academic career. It enabled me to follow up on my lessons and assignments."',
  },
  {
    id: 2,
    name: 'Amani Al-Wazzan',
    quote: 'Engineer',
    subquote:
      '“The first point of contact in any company is the most important and we must mention the technical support staff and technical department staff who have always been at the highest level.”',
  },
  {
    id: 3,
    name: 'Ahlia University',
    cover: 'data:image/gif;base64,replace_with_your_image',
    quote: 'Ahlia University',
    subquote:
      '“Al Sadeem is a very professional and reliable company that I feel confident looks after my organization’s interests at all times.”',
  },
  {
    id: 4,
    name: 'Ahmed Abu Adla',

    cover: 'data:image/gif;base64,replace_with_your_image',
    quote: 'Chemical',
    subquote:
      '"I am more than happy with the service I received from Logic Inn and the team, and I will not hesitate to try again."',
  },
]

export const Image = [
  {
    id: 0,
    name: 'Naya Abed',
    picture: '/assets/p6/image_2022-11-30_083253620-removebg-preview.png',
    cover: 'data:image/gif;base64,replace_with_your_image',
    quote: 'Foundation director',
    subquote:
      '“In my history of working with distance learning platforms, I can honestly say that there is no single company I have ever worked with that offers a better service than Sadeem’s.',
  },
  {
    id: 1,
    name: 'kian raed',
    cover: 'data:image/gif;base64,replace_with_your_image',
    quote: 'Student',
    subquote:
      '"I do not feel anxious about my use of any of Al-Sadeem products. I enjoyed the experience of Sadeem during my academic career. It enabled me to follow up on my lessons and assignments."',
  },
  {
    id: 2,
    name: 'Amani Al-Wazzan',
    quote: 'Engineer',
    subquote:
      '“The first point of contact in any company is the most important and we must mention the technical support staff and technical department staff who have always been at the highest level.”',
  },
  {
    id: 3,
    name: 'Ahlia University',
    cover: 'data:image/gif;base64,replace_with_your_image',
    quote: 'Ahlia University',
    subquote:
      '“Al Sadeem is a very professional and reliable company that I feel confident looks after my organization’s interests at all times.”',
  },
  {
    id: 4,
    name: 'Ahmed Abu Adla',

    cover: 'data:image/gif;base64,replace_with_your_image',
    quote: 'Chemical',
    subquote:
      '"I am more than happy with the service I received from Logic Inn and the team, and I will not hesitate to try again."',
  },
]
export const workoutFaq = {
  left: [
    {
      title: 'l.t1',
      content: 'l.c1',
    },
    {
      title: 'l.t2',
      content: 'l.c2',
    },
    {
      title: 'l.t3',
      content: 'l.c3',
    },
    {
      title: 'l.t4',
      content: 'l.c4',
    },
    {
      title: 'l.t5',
      content: 'l.c5',
    },
    {
      title: 'l.t6',
      content: 'l.c6',
    },
    {
      title: 'l.t7',
      content: 'l.c7',
    },
  ],
  right: [
    {
      title: 'r.t1',
      content: 'r.c1',
    },
    {
      title: 'r.t2',
      content: 'r.c2',
    },
    {
      title: 'r.t3',
      content: 'r.c3',
    },
    {
      title: 'r.t4',
      content: 'r.c4',
    },
    {
      title: 'r.t5',
      content: 'r.c5',
    },
    {
      title: 'r.t6',
      content: 'r.c6',
    },
    {
      title: 'r.t7',
      content: 'r.c7',
    },
  ],
}
