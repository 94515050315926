export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downlaod"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "title_ezar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezar Group"])},
  "title_IterStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ITER Student"])},
  "title_IterTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ITER Teacher"])},
  "title_IterAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ITER Admin"])},
  "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo."])},
  "nav": {
    "header": {
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "dawnload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "production": {
        "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al-Sadeem"])},
        "subtitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When vision, creativity and unity to create the future for a new generation"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])},
        "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODUCT"])},
        "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadeem Admin"])},
        "Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadeem Student"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
        "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadeem CheckIn"])},
        "learn": {
          "learn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEARN"])},
          "learn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learn's Admin"])},
          "learn3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learn's Student"])},
          "learn4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learn's Dashboard"])},
          "learn5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learn's CheckIN"])}
        },
        "about": {
          "aboutSadeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Sadeem"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many successful applications that demonstrate the excellence of the work method in the nebula software and the ability to develop ideas to provide the best solutions,"])}
        }
      },
      "download": {
        "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "subtitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  We have many experiences with state and private institutions professionally, easily and accurately"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download app"])},
        "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versions"])},
        "Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Version"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our insight"])},
        "learn": {
          "learn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
          "learn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use"])}
        }
      },
      "company": {
        "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We build the future"])},
        "subtitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" We’re maximizing the potential of sellers and brand owners through the power of data, AI, and marketplace expertise"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More about us"])},
        "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["services"])},
        "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Services"])},
        "Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studies Programs"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a partner"])},
        "learn": {
          "learn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REACH US"])},
          "learn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
          "learn3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
          "learn4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])}
        }
      }
    }
  },
  "pages": {
    "offers": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe now to get our special offers"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because we are the best, nothing compares to your experience with us."])}
    },
    "First": {
      "home": {
        "tittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AL Sadeem Company for Information Technology provides largest production for education"])},
        "subtittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadeem Educational Platform"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  It works to manage the educational institution and provide the  best services to the institution, which makes management  simplified for you"])},
        "mobileAppFeatures": {
          "Tecnical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Support"])},
          "tex1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 hours a day help you at any time And answer your questions and inquiries as soon as possible.  "])},
          "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User applications"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will get an app for students, an app for managers and teachers, and a login app with permissions."])},
          "Private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private chats"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private chats between management And the student and the teacher with high privacy."])},
          "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["group chats"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["system private groups ,interactive communication within the application, ability to send live broadcasts group."])},
          "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exercises students"])},
          "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending a group of exercises that help the student to understand the curriculum and to get high marks."])},
          "Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions management"])},
          "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock one of the powers each According to his responsibilities, it helps you limit responsibility and organize your work . "])},
          "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["broadcast live"])},
          "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live broadcast between prof And students with a click of a button and from within the application in cooperation with Zoom. "])},
          "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Dashboard"])},
          "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay in constant contact  to follow the progress of the instruction and ensure its quality from anywhere."])}
        }
      }
    },
    "sadeemTeacher": {
      "tittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SADEEM ADMIN"])},
      "subtittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An application dedicated to the director of the institution and all those responsible for the institution, especially the professor, according to the powers determined by the administration of the educational institution,The application carries many advantages to help the institution by giving more efficiency in education in a more than wonderful manner and from one window, and among these features that the application offers are."])},
      "features": {
        "opinion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student opinion poll"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It takes you to an interactive environment for the student through the survey and the students opinion."])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced assignments"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced homework of various types enables you to determine the level of students comprehension of the lesson, with the possibility of scheduling or archiving it and using it later.."])},
        "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic tests"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With its two types, Normal and Express, it gives you ample room to test and specify your requests Their level easily and at any time with the possibility of archiving and using them later."])},
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student exercises"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule publications of all kinds At the time and date of your choice with the possibility of archiving."])}
      },
      "featuresB": {
        "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of teachers"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A page that shows all the interactions of teachers with students."])},
        "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tracking"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teacher, manager, or administrator, when publishing or sending a message, can know who viewed the post or message that he sent."])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student report"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A list that shows all the results and interactions of the students and the last responses that he made."])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High level organization"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through this feature, the professor, principal, student, or official can access the information at a record speed, even if it was published years ago."])}
      }
    },
    "Dashboard": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DASHBOARD"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It allows you to control your organization wherever you are and save store the organization's data With advanced automation of all data,  including records of students and professors, and conducting various  statistics through which it gives you the identification of strengths  and weaknesses in the educational institution."])},
      "dashboardFeature": {
        "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Management"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add the required job title for the employee, and accordingly specific powers are defined."])},
        "Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Management"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enables you to control all students, and send notifications to know the level of the student."])},
        "Employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees Management"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It gives you full control employee powers and the ability to add and modify their data with ease."])}
      }
    },
    "registration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student registration steps"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In a simple way you can experience"])},
      "workoutTimeline": {
        "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the app"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can download the application directly via Google play and App store Via direct link"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get your username"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the organization subscribes to the Sadeem platform, you can get your own username that includes a password and username, through which you can log in to apply.."])},
        "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login by QR code"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through the QR code, which is obtained by the administration of the institution, you can easily log in to the application."])},
        "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via the web"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can open your account via web browser by entering your password and username and enjoy all the benefits."])}
      }
    },
    "SadeemStudent": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SADEEM STUDENT"])},
      "subtitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This application aims to build a new generation of learners and conscious towards a prosperous future that allows parents to know what their children do at school when they leave the house until they return, in addition to providing communication between the family management and the school immediately and quickly."])},
      "Student1Feature": {
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily exercises"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice yourself on the lessons that the professor gave you, and prove the lesson review in the form of an interactive exercise."])},
        "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private chats"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ease and speed of communication with professors or administration from within the application with the possibility of sharing media."])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weekly schedule"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge of lesson times is at your fingertips in an organized manner."])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic library"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The electronic library with its various audio, video or pdf filesYou can download and view it ."])}
      },
      "Student2Feature": {
        "broadcasts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private and public broadcasts"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactive broadcasts between the student and the professor With the use of interactive questions from the same application."])},
        "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lessons"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow them at any time and come back whenever you want."])},
        "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic tests"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A specialized section to create the best interactive questions for students by the teacher, which makes the student interact with the questions."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced filtering and post search"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can access any lesson, homework, or information published by the institution in the fastest way possible."])}
      }
    },
    "sadeemFeature": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensures better communication between the elements of the educational institution on the one hand, and the students and their parents on the otherhand, which means greater agreement and satisfaction, which will contribute to improving the educational level in the institution and ensuring follow-up and supervision by parents."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Sadeem, you will ensure an accurate assessment of students performance through many features that will help the student improve his performance, and activate positive competition among students, such as the points system, periodic assessments and reports, and protected electronic tests with correction."])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Sadeem platform simulates the needs of your entire institution, from a comprehensive financial and accounting system, to the student and employee affairs management system, warehouse and transportation management, and the authority management system. In addition to a comprehensive follow-up of employees with periodic reports."])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Sadeem, you will ensure the continuity of the educational process in various circumstances, thanks to the live broadcast feature from within the application and without the need for the use of additional platforms, the advantage of recorded lessons, and the educational bag, in addition to the rich electronic library."])}
    },
    "TechnicalServices": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical services of the Company"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through our follow-up to educational institutions,   we have found solutions to all the problems that institutions may face,   as they will not need multiple platforms and the dispersion of the management workflow in external platforms,    through Sadeem, we have provided everything from one platform"])},
      "posts": {
        "post1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systems design and development services"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We build powerful digital platforms to help companies and organizations become more flexible"])},
        "post2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting and administrative systems"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" We work with various industry and trade partners to form new mindsets and new models for effective."])},
        "post3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service educational platforms"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide our clients with quality assured services and solutions, based on years of experience and careful analysis of the requirements "])}
      }
    },
    "sadeemcheckin": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadeem Check in"])},
      "subtitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section supports viewing"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An application dedicated to educational institutions to facilitate the process of taking attendance and absence by reading and scanning the QR code of students and teachers."])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to use this program, you need to install it on your phone first, then run it and grant the camera permission to it, so the application will work directly to capture QR codes and read them automatically, application advantages:"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ Recording attendance and absence of students"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ Student exit registration"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ Student verification"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ Register attendance of teachers"])}
    },
    "backet": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic bracelet"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application supports scanning the QR code from the electronic bracelet in an easy way, which ensures speed in taking attendance and absence, and accuracy as well  Where the code is on the bracelet, and once it is scanned through the application, attendance is taken, exit, or check according to the operation to be performed."])}
    },
    "id": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic ID"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application supports scanning the QR code from the electronic identity in an easy way that ensures speed in taking attendance and absence and accuracy as well  Where the code is located on the ID  Once it is scanned through the application, attendance is taken, exit, or check according to the process to be performed."])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why to  choose us as a partners for your success"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We at Al Sadeem always strive to be the only company that provides the unique type of services, applications and systems for laboratories and institutions. Our projects speak of the quality of our work and this is what we aspire to."])},
      "Student1Feature": {
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance quality"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality never comes by chance, it is the result of honest effort, smart direction, and experienced director, it represents the wise choice of multiple alternatives."])},
        "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creativity"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the right time to monitor the compass direction of your creativity, a creative change is the companion of excellence,Al Sadeem your creative partners"])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperation understanding"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect work, less time, one spirit, creative minds gathered to analyze your requirements and fulfill them, hand in hand so that the world can see our achievement."])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsiveness"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Al Sadeem We believe in the importance of time for our customers, and the necessity of rapid completion with high quality and distinctive creativity"])}
      }
    },
    "important": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The most important characteristic of our systems"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We at Al Sadeem always strive to be the only company that provides the unique type of services, applications and systems for laboratories and institutions. Our projects speak of the quality of our work and this is what we aspire to."])},
      "Student1Feature": {
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clarity"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" gives an efficient and smooth experience without complexity."])},
        "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent update"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are committed to opening up to the fast-paced technological world."])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native Dark Mode"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every single piece of UI is natively dark mode ready."])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical support"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mission is to provide technology to everyone,and  we are available around the clock."])},
        "librar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefining the user experience of artificial intelligence, fully training him on our systems."])},
        "libra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapid response"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We help companies to become more flexible and efficient."])},
        "libr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design accuracy"])},
        "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We respect rules and processes and get things right from the first time."])},
        "lib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data protection"])},
        "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our customer data is fully protected from loss or hacking."])}
      }
    },
    "choose": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why choose us for business ?"])},
      "Student1Feature": {
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Privacy"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use our services on your cloud in an easy way for all time and injoye it ."])},
        "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Customers Service"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our technical support team stays with you until you are assured of our services."])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Pro technology"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using methods to build algorithms and using continuous development it."])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Payments"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The services provided are appropriate according to your need for the service as soon as poosible."])},
        "librar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Safety"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Al Sadeem we work to Understand customer needs and automating workflows to artificial intelligence ."])},
        "libra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Continuous updates"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["striving to build customer confidence You will always be with us ."])}
      }
    },
    "our": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have some nice services to help you setup your project and get started as fast as possible."])},
      "Student1Feature": {
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enrichment curricula"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer you the best enrichment approach through our participation in the best international companies and reliable sources."])},
        "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global competitions"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through our partnership with the international companies responsible for organizing the curricula, it is possible to participate."])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accred International"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide you with global accreditations for education from organizations responsible for measuring the quality of education."])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quality of education"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide you with a team of specialized staff and supervisors to build an educational quality plan at the best cost and the fastest time."])}
      }
    },
    "testimonials": {
      "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naya Abed"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In my history of working with distance learning platforms, I can honestly say that there is no single company I have ever worked with that offers a better service than Sadeem’s."])},
      "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kian raed"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I do not feel anxious about my use of any of Al-Sadeem products. I enjoyed the experience of Sadeem during my academic career. It enabled me to follow up on my lessons and assignments."])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amani Al-Wazzan"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first point of contact in any company is the most important and we must mention the technical support staff and technical department staff who have always been at the highest level."])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eisen School"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al Sadeem is a very professional and reliable company that I feel confident looks after my organization’s interests at all times.”"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daniel Francis"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“We are very fortunate to have built excellent partnerships with many of our clients, and have formed more than just business relationships with them; We have formed true friendships. This is what they say about us”"])},
      "cha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahmed Abu Adla"])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am more than happy with the service I received from Logic Inn and the team, and I will not hesitate to try again."])},
      "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diana Issa"])},
      "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledgeable and creative team"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farouk Qandil"])},
      "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional organization The work is always carried out in a timely manner and in a well-organized manner I cant appreciate you enough."])},
      "chatss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amir Ghazi"])},
      "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A helpful team that really works to meet our needs"])},
      "chatsss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amir Haider"])},
      "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always ready with solutions - for all kinds of problems"])},
      "chatssss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rabie almuealim"])},
      "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have been using Sadeem for Business over the past two years, after we have used other learning platforms for our organizations We were very impressed with the service we received, and the friendly manner in which we were  I feel confident that the database uploaded to the platform is secure and that any queries or concerns I have can be dealt with professionally."])}
    }
  },
  "priceing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Prices"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Al Sadeem, we seek to have our prices well thought out to suit all customers while maintaining the quality of services"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assets/teacher/e.png"])},
    "test": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We guarantee you a perfect experience with us.Join us and get special services provided by Al Sadeem, in addition to other free services."])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't miss out on opportunities"])}
    },
    "r": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing Based On Your Success"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalable Pricing"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improve the way your work, discover a brand new tool and drop the hassle once and for all."])},
      "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many bounties and rich features"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
      "n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["250 students or more"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter"])},
      "n1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500 students or more"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "n2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of students exceeds 1000"])}
    },
    "p": {
      "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own more than one company"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'If you have more than one educational institution with varying number of students, in the same country or abroad. We offer you, at Al Sadeem, special price offers and various services'"])},
      "titl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Request a special price'"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'In Al-Sadeem, we care about our customers, and we always strive to achieve their satisfaction. We care about providing high-quality services at reasonable prices.'"])},
      "tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'A group of companies in the same country'"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'In Al-Sadeem, we offer you a set of special services. If you own more than one establishment in the same country, we have a lot of services for you.'"])},
      "ti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Special services for you'"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We always strive in Al-Sadeem to provide high-quality services that guarantee you the continuity and expansion of your business.'"])}
    },
    "c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare All Plans"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How about comparing?"])}
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read our FAQs"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have a Question?"])},
      "q": {
        "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How is the payment online or by presence?"])},
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment can be made in several ways, whether electronic, or through our company bank account, or in person through our agent in your country, or in any way that is smoother for  your institution."])},
        "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can the amount be divided?"])},
        "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, it can be divided according to the agreement between the institution and our company upon completing of the contract."])},
        "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is include all additional services such as permanent technical support and training ?"])},
        "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, the agreed amount includes all these services, provided by our company to your institution."])},
        "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If we want to add features , will it be at an additional cost?"])},
        "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This depends on the category of your institution. This service is provided free of charge to some institutions, while it is at a limited cost to others."])},
        "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" How long is the subscription period by the agreed amount?"])},
        "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription with us is for a full year, meaning that the subscription cost is for 12 months, and thus the platform can serve you with any summer courses at your institution."])}
      }
    }
  },
  "admin": {
    "1": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadeem Admin"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The brains behind"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A system that works to provide high-quality services to the school administration and transform its traditional administrative system  into an integrated electronic system, which contributes to the  development and supply of educational units with new modern  knowledge channels"])}
    },
    "2": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single window"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This list ensures that you are constantly informed, directly and smoothly with all the events published by the institution or by the teacher.Through which it is possible to quickly access all publications and interact faster with students, through which organization, archiving and filtering are carried out according to academic subjects so that access to them by the professor is smooth and clear."])}
    },
    "3": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features System"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated chat system"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was built to ensure a safe communication environment between the teacher and the student and under supervision by the administration. This section supports sending and receiving all kinds of different media (audio tags, pdf files, images, various links, publications from Sadeem, etc..).This section supports the creation of:"])},
      "f": {
        "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Broadcasts"])},
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A live and interactive online lesson can be created for a specific student through the chat system"])},
        "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Groups"])},
        "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The chats section of the Sadeem platform supports the group system, as it allows the creation of private groups that can accommodate more than 200 members."])},
        "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Filtration"])},
        "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Sadeem you can filter messages into read and unread, groups and individual chat The teacher can also filter classes and people for easy access to students"])},
        "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending Media"])},
        "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application allows sending and receiving all media such as audio files, pdf files, audio fingerprints, and images"])}
      }
    },
    "4": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features System"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic testing system"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have developed a testing system within Sadeem, which is available in several types (quick, regular). Through it, the type of answer required by the student can be controlled, as it is available in more than one type so that the student can answer in different ways depending on the question requested.  He can upload an answer in the form of a video - an image - a whiteboard (smart board) - an audio fingerprint."])},
      "f": {
        "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast Test"])},
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With this type, the correction is automatic without interference from the teacher, as the teacher lists the questions and the grade due for each question, which is in the form of a multiple choice.They do not all have to be of the same type. This type enables the student to quickly obtain the result and to identify the points of failure, if any."])},
        "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal Test"])},
        "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this type, the correction is manual by the teacher, as he lists the questions and the grade due for each question, specifying the type of answer required from the student, so that the student can share the different media with the answer, such as the answer being audio - text - video - or multiple choice."])}
      }
    },
    "5": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadeem designed this section to give you complete knowledge of everything related to you, your activity, and the activity of your students, with the advanced filtering feature where you can show all notifications or notifications of a specific section or activity and There are two types of notifications:"])},
      "f": {
        "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct notifications"])},
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this type, your notification arrives outside the application, and the notification is either related to a notification by the administration of the institution, or a notification related to student activity (delivering an assignment - liking a post - commenting on a post - messages ..)"])},
        "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-app notifications"])},
        "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a dedicated section, with advanced filtering, to enable quick access to notifications and to ensure a quick response to student interactions.The notices are divided into special notices, live broadcast notices, or homework, with smooth movement between section"])}
      }
    },
    "6": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["within Sadeem"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated educational bag "])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enable the teacher to create professional video lessons with an audio tag and many other supporting media with the archiving feature as well:"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archives"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section is designed to serve as a draft for the teacher or administrator, in which he can collect an infinite number of publications in order to be able to publish and modify them later. The application also allows him to archive any published publication. Or create a new post."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule postss"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The scheduling feature ensures the publication of various publications at a predetermined time when scheduling, with the ability to archive and edit. Once scheduled, the scheduled publication is saved in a special section that the teacher can edit and share via messages."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests and Exercises"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section is dedicated to ensuring the preservation of all previously published tests and exercises and to ensure that they are used as an integrated educational package for the teacher or administrator to benefit from in the coming years, with the possibility of editing and modification."])}
    },
    "7": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Section "])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section works with very advanced filtering. The educator can control the display of the reports of the students of a particular class and a specific division, so that he can follow the students closely. With a click of a button, he obtains a comprehensive report on the student, including:"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homework results"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the results of the students homework appear, with easy access to the homework and viewing the assignment with the students answers. In this section, the students level is evaluated through a percentage."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics of attendance and absence"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section shows all the absences of the student, specified by date and time, in detail, with special notes for each absence sent by the administration. It also shows the number of justified and unjustified leaves and his attendance as well."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inform the administration of any student"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an option that allows the educator to inform the administration of the institution about a student in the event of abuse or negligence, as it was designed to ensure close follow-up of the student and ensure that he is followed up behaviorally and educationally."])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results of tests and exercises"])},
      "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All student results appear in tests and exercises according to a chronological sequence, and with an advanced classification that guarantees quick access to any test and knowledge of the students success in a specific test subject."])}
    },
    "8": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced options for publications"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through Al Sadeem platform, we guarantee that there will be ease of use and ease of publication, and that we will achieve the fastest and effective access for all students, as we have provided the teacher with many powers to control the published publications:"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repost posts"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enable the teacher or administrator to re-publish the various previously published publications for another class and another different division, while ensuring that the entire content of the publication, including images and texts, is published in full and that it is possible to edit and modify it."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit the post"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is possible to easily modify all published publications, with the feature of receiving a notification to students that the publication has been modified, and the appearance of the word “modified” at the top of the publication indicating that the publication has been modified."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post sharing feature"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through the Sadeem platform, any existing publication can be shared either through messages within the application, shared through other applications, or shared via the Qr code feature to ensure access to all students."])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the posts"])},
      "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The educator can delete any post on the platform by clicking on the three dots related to the post"])}
    }
  },
  "9": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick access"])},
    "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We created this section to facilitate access to:"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["media and images"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It gives us easy access to all the media uploaded to the system with the ease of categorizing them according to their type (audio files, images, links, videos, pdf files)."])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the most important events"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section, all the organizations events appear in a modern and modern style, as they appear in a classification and according to a chronological sequence, to ensure that you stay informed of all the important events of the organization."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincere Publications"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through this section, the most important and latest publications related to the latest events can be summarized. And if there is: Live broadcasts now, current homework, current electronic tests, when you click on any of them, the direct direction of the post is made"])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'web view'"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was created to ensure easy access to publications in a modern and modern style, as all publications are displayed in full details in the form of a grid, and when clicking on any publication, the transition to it is carried out smoothly."])}
  },
  "10": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of advanced lessons (smart)"])},
    "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section gives smart browsing, where a list of rows appears. Upon clicking, all the materials of this row appear, including what has been published from different publications (lesson, homework, live broadcast, reporting, tests) with the ability to access and display only the media for the publications."])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by class and subject"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All classes for the teacher and the educational materials assigned to him appear, with an indicator showing what has been published from, and when clicked on, this section is characterized by smooth navigation, as just dragging gives us access to publications, which are classified according to type (test lessons - homework - live broadcasts)."])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by post type"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section, after selecting the class, division, and subject, an interface appears that contains all the publications of the previously selected class, classified with a smart classification that shows the type of publication, with easy access to it through the click of a button."])}
  },
  "11": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced filtering"])},
    "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We enabled the teacher to filter different types of publications according to his subjects or according to class or division, so that he can display them smoothly on the latest news page. Where the filter enables him to access the publications faster and according to a chronological sequence."])}
  },
  "12": {
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced homework"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". The teacher was able to communicate effectively with the students, as the type of student's answer can be determined."])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". The teacher can choose a time to hand in the assignment, once it is finished, the students cannot submit any answers."])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Correction is done manually in all types, except for the type of affirmation of the solution and the type without, and found, to emphasize the student of the necessity of a specific matter, or to confirm the performance of the duty without submitting an answer from the student."])},
    "c5=": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". We also allow the teacher to re-publish the previously published assignment, for another class and another division, with all the details, with the possibility of amending it."])},
    "c=": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section will talk to us to determine the level of students' comprehension of the lesson and careful follow-up to them. They are of several types:"])}
  },
  "student": {
    "1": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadeem Student"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The brains behind"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A system that works to provide high-quality services and to  provide explanatory and educational tools and advanced curricula  that work to develop the scientific and educational level of the student"])}
    },
    "2": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section supports viewing "])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installments"])},
      "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ Total installment"])},
      "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ The remaining installment"])},
      "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ Payed installment with details of the date "])},
      "c4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ Bus installments"])},
      "c5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlling attendance, absence, and vacations within the division, with electronic reports on the status of each student that can be provided to the teacher upon request."])}
    },
    "3": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional Services"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance and absence section"])},
      "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enable the guardian and the student to follow closely the daily check-up and the excused and unjustified absences"])},
      "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where the details of the student's full attendance appear, whether he is on leave, present, or absent"])}
    },
    "4": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SADEEM  STUDENT"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is an interface in which all months of the year are displayed, and it shows in days all the important activities of the student that he has accomplished or not."])}
    },
    "5": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated chat system"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section supports multimedia sharing with the organization's staff, and also supports advanced filtering, where read and unread conversations, groups, and individual conversations can be classified.  On the Sadeem platform, the student can also send and share all kinds of multimedia (photos _ electronic board _ various links _ audio fingerprints).  Posts published in Nebula can be made in conversations."])}
    },
    "6": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced homework"])},
      "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". The teacher was able to communicate effectively with the students, as the type of student's answer can be determined."])},
      "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". The teacher can choose a time to hand in the assignment, once it is finished, the students cannot submit any answers."])},
      "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Correction is done manually in all types, except for the type of affirmation of the solution and the type without, and found, to emphasize the student of the necessity of a specific matter, or to confirm the performance of the duty without submitting an answer from the student."])},
      "c5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". We also allow the teacher to re-publish the previously published assignment, for another class and another division, with all the details, with the possibility of amending it."])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section will talk to us to determine the level of students' comprehension of the lesson and careful follow-up to them. They are of several types:"])}
    },
    "7": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic library"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rich and interactive visual, audio and read electronic library, explanatory and learning tools, and advanced curricula that work to develop the student's scientific and educational level.  To enable the student to correspond with the administration of the institution and all teachers with the ability to send various visual and audio media and pdf files."])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactive Comments"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Sadeem system enables video and audio interaction through comments and the possibility of replies, deletion and editing as well."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule posts"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The scheduling feature ensures the publication of various publications at a predetermined time when scheduling, with the ability to archive and edit. Once scheduled, the scheduled publication is saved in a special section that the teacher can edit and share via messages"])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily and Monthly degrees"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To follow up smoothly and immediately on the results sent by the administration and teachers."])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student reports"])},
      "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teacher can follow the students closely, he obtains a comprehensive report on the student, including the results of tests and exercises,homework,attendance and absence."])}
    },
    "8": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactive comments"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Sadeem system enables video and audio interaction through comments and the possibility of replies, deletion and editing as well.  The name and photo of the owner of each comment appear."])}
    },
    "9": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligent electronic testing system"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ensure that the level of the student is determined, it is available in two types, the rapid and the regular, and we include through it the possibility of solving through multiple media (photos _ video _ white board _ multiple choice _ audio fingerprints). It is also possible to access all the completed and corrected tests and exercises through a special section that contains all the tests."])}
    },
    "10": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For daily and monthly degrees"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To follow up smoothly and immediately on the results sent by the administration and teachers, as there is a section dedicated to each of the daily and monthly grades, while ensuring easy navigation between them.It is possible to choose and specify the month, and all grades are displayed with an accurate determination of the student’s level and his success or failure rate"])}
    },
    "11": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile personly"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A section dedicated to student reports and to follow up on the academic level through a special report that contains the results of all tests, assignments, exercises, attendance and absence of the student, with determining the success rate in each subject. This section includes:"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results of tests and exercises"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowing the percentage of student success in a specific test subject."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homework results"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the results of the students homework appear, with easy access to the homework and previewing the assignment with withholding the possibility of modification to it. Also, in this section, the students level is evaluated through a percentage that shows the extent of his progress and his level is monitored."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics of attendance and absence"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section shows all the absences of the student, specified by date and time, in detail, with notes for each absence sent by the administration. It also shows the number of justified and unjustified leaves and his attendance as well."])}
    },
    "12": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weekly Schedule"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To view the class program, determine the detailed time of the class, and whether it is electronic or in-person, and mention the order of the class"])}
    },
    "13": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than one account on one device"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both applications (teacher _ student) support adding more than one user on one device, to ensure that the desired benefit is achieved."])}
    }
  },
  "dashboard": {
    "1": {
      
    },
    "2": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehensive and periodic statistics"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for students and employees in the institution"])}
    },
    "3": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodic stats"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section shows in detail the activities of the teachers and the extent of their interaction through the platform with the students, as it shows statistics for each teacher showing the number of publications and the number of messages sent.  Through this statistic, teachers are evaluated and classified according to who are the best teachers and who need follow-up."])}
    },
    "4": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated chat system"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the Sadeem platform, we do not adopt traditional methods of messaging. We rely on effective communication between the institution's members and the student's guardian. When using the messages section in Al Sadeem, the administration will be able to send documents, video clips, and various media, and it can also create groups that can accommodate more than 200 members under broad powers granted by the administration to those who can send messages."])}
    },
    "5": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The institution department"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["related to the management "])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through this section, you can add and define classes, divisions, and academic subjects, and grant access permission to professors to them.The data entry or administration can also distribute classes to teachers, include exam and weekly schedules as well, define transport lines on a map, or archive the entire institution's data."])}
    },
    "6": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting and Finance System"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The electronic accounting and billing system includes:Student installments with the ability to filter and print them.  Employee vouchers.  Bus fees.  imports and expenses.  This section was designed to be safe and suitable for the educational sector with all its exact requirements and all that the institution's management needs."])}
    },
    "7": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-on-One"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow-up and supervision"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With ease and simplicity, the educational supervisor or the director of the institution follows up on the professors’ classes successively and the attendance of students and professors in the lesson, and it is also possible to know the scientific progress of the institution and the level of teachers’ education for students through smart and accurate statistics, and the system is equipped with many statistics, so that the administration can take the appropriate decision."])}
    }
  },
  "check": {
    "1": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadeem Check in"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The brains behind"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" An application dedicated to educational institutions to facilitate the process of taking attendance and absence by reading and scanning the QR code of students and teachers. The application performs its tasks perfectly, as through the survey you can take the attendance and absence of students of a specific class and aspecific section within a few minutes, with the ability to know whether the student belongs to the institution or not."])}
    }
  },
  "partner": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become Partner"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share success and excellence with us. We strengthen our strong relationships in all markets that will help us reach our customers  everywhere. We welcome you as a partner with us."])},
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons why we are the best for"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customers and partners"])},
    "s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieving fast and sustainable results"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By focusing on the customer and his needs At ALSadeem, our flexible approach to data strategy, data architecture, data management, data science and data program management enables our clients to make quick wins in transforming business vision into data-centric innovations."])},
    "s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A dynamic team of data experts"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Al Sadeem, we are committed to achieving success for our customers and employees - thats why we have the best expertise, our team has years of experience, which makes us the ideal partner for all your data needs."])},
    "s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our advisory framework"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al-Sadeem has developed a unique consultative engagement framework that allows us to generate quick wins for our clients and reduce the cost and time of data monetization."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We welcome you as a partner with us and Enjoy your time :) "])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email"])}
  },
  "events": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Latest News"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Blog"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On December 11, 2017"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The launch of the Sadeem Plus platform in the schools of the First Rusafa Directorate, Baghdad"])},
    "v1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And Kia Ministry of Education currently, Mr. Haider Al-Khafaji and Mr. Asaad Salim are representatives of the management of Al-Sadeem Company, where, with the blessing of parents and media lenses, the first-of-its-kind educational platform, with its benefits and advantages, was launched. Rusafa First Directorate of Education to where Rusafa was ranked first in Iraq in Baghdad Always in Al Sadeem Information Technology, we will be the pioneering company in issuing technologies concerned with raising the quality of education in educational institutions."])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On October of 2017"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adopting a project for training the first Rusafa Education Schools, under the auspices of its Directorate of Education"])},
    "v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Mr. Salman Al-Shammari (senior educational specialist in the Ministry, representing the Director General of Educational Supervision in the Ministry) - Mr. Baher Al-Zoubi (Smart Brain Responsible in the Middle East and Canada)"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celebration Al Sadeem Plus "])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadeem Plus course for educational supervisors and institutions, which was held at Al-Hariri School in Baghdad "])},
    "v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al-Sadeem Information Technology Company, in cooperation with Smart Brain Company for mental arithmetic, held the fourth Gulf competition in the United Arab Emirates / Emirate of Sharjah / Sharjah Chamber of Commerce and Industry, in which the Gulf countries participated (Kuwait - Saudi Arabia - Bahrain - Qatar UAE - Iraq) on 8/27/2022 The number of participating students from Iraq is 14 students. The number of students who achieved 10 centers at the Gulf level."])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June 21, 2018"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zain Iraq celebrates the success of the Sadeem Plus application by introducing technological and digital solutions "])},
    "v4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It provides the possibility to follow up the educational level of parents, daily attendance, homework, and communication with the school administration through electronic notifications."])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The fourth Gulf competition in the United Arab Emirates"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In cooperation with Smart Brain Company for mental arithmetic, held the fourth Gulf competition in the United Arab Emirates "])},
    "v5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sharjah Passage / Sharjah Chamber of Commerce and Industry participated in the Gulf countries (Kuwait - Saudi Arabia - Bahrain - Qatar UAE - Iraq) on 8/27/2022. The number of participating students from Iraq is 14 students. The number of students who achieved 10 positions at the Gulf level. And because we, in al-Sadeem, believe that competition is the fuse of creativity and innovation, and we have always been keen to represent the name of the State of Iraq, we have worked hard to achieve more progress, provide ways of excellence and perseverance, and create opportunities for the people of Iraq to raise the name of their country in international forums."])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November 17, 2022"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al-Sadeem Company cooperated With the management of Smart Brain sponsored by Mama Acer School"])},
    "v6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al-Sadeem Company cooperated With the management of Smart Brain sponsored by Mama Acer School in Baghdad, a ceremony honoring the students of the Golden Account course, the winners of the Gulf competition that was held in Sharjah, and the winners of the first places from all governorates of Iraq. The honoring ceremony was attended and honored by"])}
  },
  "learns": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Sadeem Dashboard"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On December 11, 2017"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By going to the conversations and clicking on the Add (+) button located at the bottom left of the screen, and then clicking "])},
    "v1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on the name you want to have a conversation with, a list will appear containing all the employees, including teachers and administrators."])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I access the published lessons and publications in an organized and fast manner?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through the list in the navbar, we choose the list, where the lessons interface allows us to  "])},
    "v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organize all the lessons that were previously created. Categorized according to the type tests - live broadcasts - lessons There is a shortcut at the top left of the interface to access media only."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I view a report on my results and all my activities in the institution?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can get your own report by clicking on the profile section in the navbar, then going "])},
    "v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and clicking on your profile. A list will appear containing all your homework results, test results, and exercises. With a detailed report of your attendance and absence. And features can be added"])}
  },
  "learna": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Sadeem Admin"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the list of lessons? And what is the use of it?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The list of lessons guarantees you quick access to all your classes with a classification of materials for each class."])},
    "v1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After selecting the class for which you want to access all its publications, all materials appear, and when you click on any material, all publications from lessons _ exams _ homework, With the possibility of publishing a professional lesson again through the Add button located on the left of the screen"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I control the content on the News Feed page!?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We choose the filter button at the top left of the interface of the News Feed page. It is present in three lines. When you click on it, "])},
    "v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a list pops up containing three fields in which rows_article - and the type of publication. We choose what we want to access from publications related to a class To exit the filter mode, there is an x ​​button located on the left of the interface, press it, and it will exit the filter mode.."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the list of lessons? And what is the use of it?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The list of lessons guarantees you quick access to all your classes with a classification of materials for each class. "])},
    "v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After selecting the class for which you want to access all its publications, all materials appear, and when you click on any material, all publications from lessons _ exams _ homework, With the possibility of publishing a professional lesson again through the Add button located on the left of the screen."])}
  },
  "learnd": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Sadeem Dashboard"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I manage the powers and grant permissions to employees in my organization!?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role management:Through the Role Management section located on the main page of the control panel on the right, "])},
    "v1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["we choose it at the beginning. An interface will appear that contains the “Add Role” button. After clicking on it, a list will pop up containing the name of the role and a field designated for notes. The field is filled in, and then the types of permissions ."])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I add a new teacher or employee!"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff section:From within the Sadeem platform, you can easily and securely create accounts for professors or employees and control their permissions,"])},
    "v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["through the Employees section and then choose the Add button. An interface will appear that contains fields dedicated to inserting data for each employee. After entering and selecting Done, the employee will be added with his username (name user_password)."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the method of creating a row on the board ?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have collected for you on the Al-Sadeem platform everything that is needed to control the institution,such as following up on classes, materials, and teachers,"])},
    "v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with ease and clarity. There is a section called “Classes”, which we enter and choose the Add button and add a new class and a new division.In order to add materials, we remain in the institution’s section,  materials is done in a simple and easy way by clicking ."])}
  },
  "about": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A High-Performing Senior Elite Team"])},
    "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Al Sadeem, we have a highly skilled team Join us and meet a group of technology experts to save time, boost performance and drive results on your growth journey."])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Company Values"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedicated Team"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respect"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We value everyone and treat people with dignity and respect."])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrity"])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Al Sadeem, we build trust through responsible actions and honest relationships."])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe in flexibility"])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We understand that work and life blend together, so we value a casual and flexible workplace."])},
    "t8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer obsessed"])},
    "t9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe in the power of will. We dont seek the easy way, we look for the toughest challenges. Our collective resilience is our secret weapon."])},
    "1": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Partners"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They talk about us"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PEDAGOGY: provides consulting services to educational institutions to obtain accreditations in the quality of education It is interested in developing and creating educational and training curricula and converting them into interactive books and digital electronic content. It also owns a publishing house Textbooks, in addition to controlling the quality of education, qualifying, training and developing educational cadres in educational institutions "])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certiport: is a leading global provider of performance-based examination certification and testing solutions Practice for the academic institutions, workforce and corporate technology markets.Certiport exams are offered through an extensive networkIt has more than 15,000 Certiport-accredited test centers worldwide.  Certiport delivers millions of exams each year through the secondary, post-secondary, and workforce technology markets and corporate technology markets  "])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Brain: It is a program for mental mathematics that contains a set of levels that enable the student, after completing it, to perform mathematical operations (addition, subtraction, multiplication, and division of whole numbers and fractions) mentally without the need to use any mathematical tool. about the platform used The platform used by Smart Brain students is to practice students on exercises specific to the curriculum, and these exercises are custom made "])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom: A San Jose, California company that provides video telephony and Internet chat services through a cloud-based peer-to-peer software platform. Zoom provides video calling software used in remote conferences and meetings, as well as work, education and social relations remotely."])},
      "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Group: The Make Group team, located in Kuwait, is a leading company in the field of technology that promotes the use of modern technology and innovation through the development of self-operating products and constructions. We also work on supplying tools and machines . "])},
      "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon: It is an American multinational technology company focused on e-commerce, cloud computing, online advertising, streaming It has been referred to as one of the worlds most influential economic and  along with Alphabet (Google), Apple, Meta (Facebook), and Microsoft. "])}
    }
  },
  "versions": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Sadeem Versions"])},
    "1": {
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-Night mode improvements"])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-Improvements in the tests section for easy access to students solutions and ease of display."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-Improvements in uploading videos and images and adding cache for easy media reference."])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-Chats section Make it possible to see the profile of the student in the group"])},
      "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-Improve homework and add a button for correction and quick access to students answers."])},
      "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6-An increase in the powers of the administration and the teacher, adding the power to delete students comments"])},
      "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7-Improving the exercises and tests section and enabling the student to upload more than one picture."])},
      "t8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8-Development of the students installments department"])},
      "t9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9-Night mode improvements"])}
    },
    "2": {
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-Performance speed"])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-Development of notifications section."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-Group filtering feature in messages."])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-Filter feature in the student section"])},
      "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-Improve homework and add a button for correction and quick access to students answers."])},
      "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6-Adding the ability to answer and upload more than one picture by the student in the homework."])},
      "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7-Improving the exercises and tests section and enabling the student to upload more than one picture."])},
      "t8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8-Development of the students installments department"])},
      "t9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10-Night mode improvements"])}
    },
    "3": {
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-Filter more accurately for quick access to publications"])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-The ability to control the zoom meeting time (increase or decrease)"])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-Adding the ability to view solutions from posts"])}
    },
    "4": {
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-Facilitating the filtering process for the student as well, to be more accurate."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-Supporting tests and exercises for videos with high accuracy."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-Development and improvements in the conversations section within the application"])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-Tighten the password control"])},
      "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-Support sharing external links within the application."])}
    },
    "5": {
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-Increasing work flexibility in both types of Zoom meetings."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-A high level of security."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-A faster, more convenient and safer way."])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-Adding the ability to review questions."])},
      "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-Activate the possibility of evaluation in Google."])},
      "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6-Increase control of authorities to increase security."])}
    },
    "6": {
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1- Language developments so that the application supports three languages"])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2- Night mode improvements"])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3- Adding different currencies to support the financial section"])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4- Adding new interfaces that support quick access to the user"])},
      "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5_ Activate single browsing"])}
    },
    "7": {
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1- Tighten the password control"])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2- Increased security"])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3- Improved handling of user interfaces to make them smoother"])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4- Adding a section for exercises"])}
    }
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When vision unites to create the future of the new generation"])},
    "Discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover"])},
    "ts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Services"])},
    "ec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrichment Curricula"])},
    "bp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a partner"])},
    "Q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Of Education(dev)"])},
    "ALSadeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALSadeem"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agents(dev)"])},
    "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News & Events"])},
    "j": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Opportunity(dev)"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "sc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Center"])},
    "tu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use"])},
    "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])}
  },
  "tabel": {
    "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
    "fu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["full training"])},
    "ts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical support"])},
    "cu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuous updates"])},
    "us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited storage"])},
    "ea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy all the services"])},
    "an": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A number of free accounts"])},
    "ie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International enrichment curricula"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalization"])},
    "d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Adding features that the organization desires to the platform for free"])}
  },
  "ShowMorebtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show More"])},
  "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because we are the best, nothing compares to your experience with us"])},
  "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more details, you can contact us through the numbers"])},
  "l": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take the time to read our FAQs"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have an idea for an app or website?"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain to us and we implement the most appropriate prices and high professionalism Not only professionalism in our work, but we give ease and simplicity in browsing your site to give a unique experience to your customers. We offer hosting service on the latest servers with digital security services to protect websites and applications Our team works on various programming languages, which meet your project needs. To transform it from an idea into an integrated work system"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dont need all these features, why I pay for them?"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The more you use what you have, the more you have to use, i.e. the more features you use The one in your hands, you needed more features to use.Despite this, the interest of your organization and your satisfaction are our priorities, so we can provide Features as much as your organization needs, at a lower cost and fully utilized features, lets move away from Any confusion for your honors employees, and any waste of your financial resources"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What distinguishes you from other competitors?"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer you a platform built on years of careful analysis of each Problems and difficulties that may be an obstacle to the success and distinction of any educational institution,With completely new features and options, in addition to our company giving you the possibility to deal Personally and testing all its features for two full months, we guarantee you that it will be crowned later With full cooperation, because you will experience ease of operations, organization and accuracy."])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are the servers located?"])},
    "c4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributed on several sites, such as Germany and America and with Google. That is, it is a link  Complete, secure and impenetrable data backup to avoid losing any data.."])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance registration via bracelet or ID?"])},
    "c5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, of course, both the identity card and the bracelet are present. They are personal to the student and carry  CODE QR Through which absence and attendance can be recorded. We prefer CARD ID as the visual identity is better, easy to replace In the event that it is lost by the student and at a lower cost, the institution will bear it Fully required in terms of recording absence and attendance. So in the nebula of possibilitie It is there and you just have to choose."])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will the platform solve paper and loss problems?"])},
    "c6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information can be fully and systematically archived and automated across the platform  Refer to it at any time and accurately and without loss or loss of information.The possibility of management controlling the institution completely through  Platform  Through the platform, you can follow the performance of employees and students and their interaction on the platform."])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many people does the board need to manage?"])},
    "c7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only one person, the data entry operator, is able to fully manage the dashboard smoothness."])}
  },
  "r": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For full staff training and data entry?"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are fully responsible for training the data entry, and the entire staff until confirmation From the full mastery of working on the platform, and the training may be in-person or online  via the Zoom program. In addition to the presence of guidance videos explaining the features and how  use it."])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there a free period for testing the platform?"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer you a free trial period of up to two months, to make sure of all the features  and services provided by us, and to verify the extent to which your institution benefits from our platform  So that your decision is based on a full study and realistic results.."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As for technical support, how much is it available?"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In our company, we provide technical support teams that are available around the clock and throughout the year And with a quick response, it is there to help the staff and parents in case they encounter any  Problematic in how to deal with the platform."])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there a possibility to add some features ?"])},
    "c4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of course, our technical team with its programmers is able to add any additional feature or service to the platform  With what the institution deems required and necessary for its work and benefit from our platform in the fullest way"])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In  event there is more than one student form family?"])},
    "c5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, of course, our company is able to build a platform for your organization, with servers  Private and separate, but at a higher cost."])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it possible to create a special platform for school?"])},
    "c6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each student needs his own user, but it is possible to open more than one account on the same mobile phone."])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can control completely from the dashboard?"])},
    "c8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, of course, it is possible to control the accounts and the powers of the account user. and freeze it  And other parts of the board completely as desired by the administration, or as required."])}
  },
  "contact": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach Out"])},
    "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Companies support"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They are present in the following Countries: UAE - Lebanon _ Syria _ Iraq _ Egypt"])},
    "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Support"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellence in customer service"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide the best care, improve efficiency and keep solutions up to date"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamlined design"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The professional and innovative design of our products is the first step to making them suitable for all work environments and use in the most attractive way."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High quality technology"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We love what we do, and we believe in providing our customers with quality service at competitive prices"])}
  },
  "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Posts"])},
  "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "select-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Language"])},
  "offline-ready": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("appName")), " is ready to work offline"])},
  "need-refresh": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A new version of ", _interpolate(_named("appName")), " is available, click on reload button to update."])},
  "reload-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
  "close-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Sadeem is important to your organization"])},
  "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through our follow-up to educational institutions, we have found solutions to all the problems that institutions may face, as they will not need multiple platforms and the dispersion of the management workflow in external platforms, through Sadeem, we have provided everything from one platform"])},
  "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical support center"])},
  "ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are a AlSadeem customer, you can request assistance directly from the support team regarding technical and technological problems.Get the support you need directly from our team, who are available around the clock to assist you and answer your inquiries."])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom support : +963967594390"])},
  "ss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies support : +9647810003954 "])},
  "m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management control panel"])},
  "m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" A system that works to provide high-quality services to manage the organization and convert its traditional paper system into an electronic system for ease of control."])},
  "m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Role management"])},
  "m4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student management"])},
  "m5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee management"])},
  "m6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial managment"])},
  "m7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Store Management"])},
  "m8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover More"])},
  "moreinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More info"])},
  "ReadMorebtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
  "TryMorebtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it now"])},
  "getStartbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start now"])},
  "contacusbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free trial"])}
}