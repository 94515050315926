<template>
  <!-- ***** Video Area Start ***** -->
  <section class="video-section section-heading">
    <div class="myContainer">
      <!-- Video Area Start  '@/assets/images/bg-img/video-img.jpeg' -->
      <div class="video-area" :style="cssProps">
        <div class="video-play-btn">
          <a class="video_btn glightbox"
            ><i class="icon-control-play" aria-hidden="true"></i
          ></a>
        </div>
      </div>
    </div>
  </section>

  <!-- ***** Video Area End ***** -->
</template>

<script setup>
import "glightbox/dist/css/glightbox.css";
import "glightbox/dist/js/glightbox.js";
import GLightbox from "glightbox";
import { ref,onMounted} from 'vue'


      const cssProps= ref( {
        backgroundImage: `url(${require("@/assets/images/bg-img/video-img.jpeg")})`,
      })
  
  onMounted(()=>{
    //lightbox settings
    GLightbox({
      selector: ".glightbox",
      touchNavigation: true,
      href: "https://www.youtube.com/watch?v=MQYc4-AQJuI",
      type: "video",
      source: "youtube", //vimeo, youtube or local
      width: 900,
      // 'height':900,
      autoPlayVideos: "true",
    });
  })
</script>

<style scoped>
.video-section {
  background-color: var(--section-color2);
  padding-bottom: 15px;
}
.video-my {
  padding-top: 30px;
  position: relative;
  z-index: 1;
  height: 500px;
  width: 100%;
  border-radius: 2px;
}

.main_banner_area .video-my {
  margin-top: 0px;
  position: relative;
  z-index: 1;
  height: 500px;
  width: 100%;
  border-radius: 2px;
}

.video-my:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  /* background-color: rgba(67, 206, 162, 0.50); */
  opacity: 0.6;
  border-radius: 30px;
}

.video-my img {
  height: 500px;
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.video-my .video-play-btn a {
  width: 80px;
  height: 80px;
  background-color: #41c9a2;
  color: #fff;
  text-align: center;
  display: block;
  font-size: 36px;
  line-height: 86px;
  padding-left: 5px;
  border-radius: 50%;
  top: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
}

.video-my .video-play-btn a:hover {
  background-color: #fff;
  color: #41c9a2;
}

.video-area {
  padding-top: 50px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  height: 700px;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 50px rgba(0, 0, 0, 0.08);
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.08);
}

.video-area:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  background-image: -moz-linear-gradient(
    0deg,
    rgba(67, 206, 162, 0.95) 0%,
    rgba(24, 90, 157, 0.9) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(67, 206, 162, 0.95) 0%,
    rgba(24, 90, 157, 0.9) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgba(67, 206, 162, 0.95) 0%,
    rgba(24, 90, 157, 0.9) 100%
  );
  background-image: linear-gradient(
    0deg,
    rgba(67, 206, 162, 0.95) 0%,
    rgba(24, 90, 157, 0.9) 100%
  );
  opacity: 0.6;
}

.video-area .video-play-btn a {
  width: 80px;
  height: 80px;
  background-color: #fff;
  color: #41c9a2;
  text-align: center;
  display: block;
  font-size: 36px;
  line-height: 86px;
  padding-left: 5px;
  border-radius: 50%;
  top: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
}

.video-area .video-play-btn a:hover {
  background-color: #41c9a2;
  color: #fff;
}

.box-icon-1 {
  margin-bottom: 0px;
  padding: 25px;
}

.box-icon-top {
  display: flex !important;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

.box-icon-1 i {
  margin-right: 15px;
  font-size: 30px;
  color: #43cea2;
}

.box-icon-1 h4 {
  font-size: 25px;
  font-weight: 600;
  color: #346da7;
}

.box-icon-1 p {
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  .video-area {
    height: 300px;
  }
}
</style>
