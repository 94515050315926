<script setup lang="ts">
import { defineEmits } from "vue";
import DarkImage from "@/components/layout/megamenu/image/DarkImage.vue";

const emit = defineEmits(["close", 'backToMenu']);
const localRtl = localStorage.getItem(`isRtl`);

</script>

<template>
  <div class="megamenu-inner">
    <button class="close-button" @click="emit('close')">
      <i class="iconify" data-icon="feather:arrow-left"></i>
    </button>
    <div class="columns">
      <div class="column is-4">
        <div class="megamenu-block">
          <div class="media">
            <div class="media-left is-resources">
              <span
                class="iconify"
                data-icon="heroicons-solid:briefcase"
                data-inline="false"
              ></span>
            </div>
            <div class="media-content">
              <div class="d-flex justify-content-between align-items-center my-3">

                <h3 class="m-0">{{ $t("nav.header.download.titel") }}</h3>
                       <i
          class="fa fa-1x backToMenu"
          :class="localRtl === 'true' ? 'fa-arrow-left' : 'fa-arrow-right'"
          @click="emit('backToMenu')"
        ></i>
              </div>
              <p>
                {{ $t("nav.header.download.subtitel") }}
              </p>
              <br><br>
              <a
                href="https://app2.sadeemlight.com"
                target="app2.sadeemlight.com"
                
              >
                <span>{{ $t("nav.header.download.content") }}</span>
                <i
                  class="iconify"
                  data-icon="feather:arrow-right"
                  data-inline="false"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="megamenu-block ml-6">
          <h4>{{ $t("nav.header.download.PRODUCT") }}</h4>
          <ul>
            <li>
              <a
                href="https://app2.sadeemlight.com"
                target="app2.sadeemlight.com"
                class="list-link"
              >
                Sadeem Admin
              </a>
            </li>
            <li>
              <a
                href="app.sadeemlight.com"
                class="list-link"
                target="app.sadeemlight.com"
              >
                Sadeem Student
              </a>
            </li>
            <li>
              <a
                class="list-link"
                href="Dashboard.sadeemlight.com"
                target="Dashboard.sadeemlight.com"
              >
                Sadeem Chick In
              </a>
            </li>
            <li>
              <a
                class="list-link"
                href="Dashboard.sadeemlight.com"
                target="Dashboard.sadeemlight.com"
              >
                Dashboard
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="column is-2">
        <div class="megamenu-block ml-6">
          <h4>{{ $t("nav.header.download.Admin") }}</h4>
          <ul>
            <li>
              <RouterLink to="/" class="list-link">
                {{ $t("nav.header.download.Student") }}
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/" class="list-link">
                {{ $t("nav.header.download.dashboard") }}
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/" class="list-link">
                {{ $t("nav.header.download.learn.learn1") }}
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/" class="list-link">
                {{ $t("nav.header.download.learn.learn2") }}
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>
      <div class="column is-4 b-hidden-mobile b-hidden-tablet-p">
        <div class="megamenu-block is-left-bordered">
          <div class="image-block">
            <DarkImage
              :src="require('@/assets/images/header.png')"
              alt="Megamenu image"
              width="200"
              height="100"
              class="imd"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
a {
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;
}
.backToMenu {
  display: none;
  padding-inline-end: 1.8rem;
  
}
@media (max-width: 767px) {
   .backToMenu {
    display: inline-block !important;
  }
}
</style>
