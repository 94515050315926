<template>
<section id="Benefits">
  <div class="contmyContainerainer">
    <div class="row">
      <div class="col-12">
        <!-- Section Heading Area -->
        <div class="section-heading text-center"  data-aos="fade-up">
          <h2 class="title-benefits">Key benefits</h2>
          <p class="mt-3">Lorem ipsum dolor sit amet, consectetur Proin gravida nibh vel velit auctor aliquet.</p>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>Product credits</h4>
            <p>Google product credits for your tech stack</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>Training</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>Equity-free support</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>Strategic support</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>Mentoring</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>Access to experts</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>A professional team</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>Technical project partnership</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>Early access</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>A professional team</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>A professional team</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card card-benefits">
          <div class="card-body">
            <img class="img-fluid" src="@/assets/images/products/light.png">
            <h4>A professional team</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
</template>



<style scoped>

#Benefits{
  margin-bottom: 30px;
}
.title-benefits {
  text-align: center;
  padding-bottom: 35px;
  position: relative;
  display: inline-block;
  font-weight: 300;
  color: #636d75;
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;

}

.title-benefits::before {
  content: "";
  background: red;
  width: 70px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  margin: auto;
}

.title-benefits:after {
  content: "";
  background: var(--main-color);
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}

.card-benefits{
  justify-content: center;
  justify-items: center;
  text-align: center;
  padding: 5px;
  margin-bottom: 30px;
  -webkit-transition-duration: 800ms;
  -o-transition-duration: 800ms;
  transition-duration: 800ms;
  border-radius: 10px;
  min-height: 275px;

}
.card-benefits:hover {
  -webkit-box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.card-benefits img{
  height: 120px;
  margin: 0 auto;
  padding-bottom: 8px;
 /* padding-top: 24px;*/
}
.card-benefits h4{
  font-size: 18px;
  font-weight: 400;
  letter-spacing: initial;
  line-height: 28px;
  margin-bottom: 10px;
}
.card-benefits p{
  font-size: 16px;
  line-height: 20px;
}


@media (max-width: 599px){
  .card-benefits p{
    font-size: 12px;
    line-height: 20px;
  }
}
</style>