<template>
  <section class="screenshots bg-white" id="screenshots">
    <div class="myContainer">
      <div class="row">
        <div class="col-12">
          <!-- Heading Text -->
          <div class="section-heading text-center">
            <h2 class="title-screen2">App Screenshoots</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <swiper
            :centeredSlides="true"
            :loop="true"
            :slidesPerView="3"
            :breakpoints="{
              '820': {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              '1024': {
                slidesPerView: 5,
                spaceBetween: 25,
              },
            }"
            :spaceBetween="25"
            :navigation="true"
            :modules="modules"
            class="mySwiper2"
          >
            <swiper-slide>
              <img src="@/assets/images/slider-img/slide1.png" />
            </swiper-slide>
            <swiper-slide
              ><img src="@/assets/images/slider-img/slide2.png"
            /></swiper-slide>
            <swiper-slide
              ><img src="@/assets/images/slider-img/slide3.png"
            /></swiper-slide>
            <swiper-slide
              ><img src="@/assets/images/slider-img/slide4.png"
            /></swiper-slide>
            <swiper-slide
              ><img src="@/assets/images/slider-img/slide3.png"
            /></swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";
// Import Swiper styles
import "swiper/css";
import { Navigation } from "swiper";
/*import "swiper/css/navigation";*/

const modules = ref([Navigation]);
</script>

<style scoped>
#screenshots {
  background: linear-gradient(90deg, #5f57ea 0, #9647db);
  padding-bottom: 50px;
}

.title-screen2 {
  text-align: center;
  padding-bottom: 35px;
  position: relative;
  display: inline-block;
  color: white;
  font-weight: 300;
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;
}

.title-screen2::before {
  content: "";
  background: #fff;
  width: 70px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  margin: auto;
}

.title-screen2:after {
  content: "";
  background: #fff;
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}

.mySwiper {
  margin: 0 30px;
  position: relative;
  height: 400px;
}
.swiper-button-next,
.swiper-button-prev {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 16px;
}
.swiper-button-prev {
  left: 0;
}
.swiper-button-next {
  right: 0;
}
.swiper-slide-active img {
  transform: scale(1.1);
  transition: 500ms;
}
.swiper-slide {
  transition: 500ms;
}
</style>
