<template>
  <!-- frameworks Start-->
  <section id="frameworks">
    <div class="myContainer">
      <div class="row mt-4 mb-4">
        <div class="col-md-6 d-block align-self-center">
          <h2 class="myTitle mt-3">
            We work with modern and standard-base web frameworks
          </h2>
          <p>
            Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo
            odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non
            mi porta gravida at eget metus.<br />
          </p>
        </div>
        <div class="col mt-2">
          <div class="row">
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img
                  class="img-fluid"
                  src="@/assets/images/frameworks/microsoft-logo.png"
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img src="@/assets/images/frameworks/Adobe.png" />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img
                  src="@/assets/images/frameworks/Autodesk-logo-rgb-black-gray.png"
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img
                  class="img-fluid"
                  src="@/assets/images/frameworks/ic3-logo-smaller.png"
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img
                  class="img-fluid"
                  src="@/assets/images/frameworks/ESB_Logo.png"
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img
                  class="img-fluid"
                  src="@/assets/images/frameworks/Swift_logo_Apple.png"
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img
                  class="img-fluid"
                  src="@/assets/images/frameworks/intuit-logo-2203.png"
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img src="@/assets/images/frameworks/ec-council-logo.png" />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img
                  class="img-fluid"
                  src="@/assets/images/frameworks/unity-master-black.png"
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img
                  class="img-fluid"
                  src="@/assets/images/frameworks/CSB-logo.png"
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img
                  class="img-fluid"
                  src="@/assets/images/frameworks/pmi_logo.png"
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-frame" data-aos="fade-up">
              <div
                class="text-nowrap d-flex flex-row justify-content-around align-items-sm-center align-items-md-center align-items-lg-center justify-content-xxl-start card-frameW"
              >
                <img src="@/assets/images/frameworks/ITS-Logo-Wide.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- frameworks End-->
</template>

<script setup>

</script>

<style scoped>
/* frameworks */

#frameworks {
  padding-top: 30px;
  padding-bottom: 35px;
  background-color: var(--section-color);
}

#frameworks h2 {
  color: var(--main-color2);
}

.card-frameW {
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 1px 16px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  padding: 5px;
  background-color: var(--section-color2);
}

#frameworks h4 {
  font-size: 12px;
  font-weight: bold;
}

#frameworks img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.col-sm-6.col-lg-3.col-frame {
  margin-top: 10px;
}

.d-table-row.Card-frame {
  box-shadow: 0px 1px 5px 1px #cfcfcf;
  border-radius: 13px;
}
@media (max-width: 767px) {
  .card-frameW {
    width: 100%;
    height: 100px;
    -webkit-box-shadow: 0 1px 16px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 16px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding: 10px;
  }
  .myTitle {
    color: var(--main-color3) !important;

    font-size: 20px;
    font-weight: 400;
    margin-bottom: 12px;
  }
  #frameworks img {
    width: 100%;
    height: 75px;
    object-fit: contain;
  }
}
</style>
