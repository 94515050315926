<script setup lang="ts">
import Subtitle from './SubTitle.vue'
import Title from './TitleSetup.vue'

import {withDefaults,defineProps,computed} from "vue";

export interface SectionTitleProps {
  title: string
  subtitle: string
  comman?: string
  inverted?: boolean
  spaced?: boolean
}

const props = withDefaults(defineProps<SectionTitleProps>(), {
  title: undefined,
  comman: undefined,
  subtitle: undefined,
  inverted: false,
  spaced: false,
})

const titleClasses = computed(() => [props.inverted && `is-inverted`])

const blockClasses = computed(() => [props.spaced && 'mb-16'])

const subtitleClasses = computed(() => [
  props.inverted ? `text-white` : 'text-gradient',
])
</script>

<template>
  <div class="has-text-centered" :class="blockClasses">
    <Title tag="h2" :size="3" weight="bold" :class="titleClasses">
      <span>
        <slot>{{ $t(props.title) }}</slot>
      </span>
    </Title>
    <Subtitle tag="h4" :size="5" weight="bold">
      <span :class="subtitleClasses">
        <slot name="subtitle">{{ $t(props.subtitle) }} </slot>
      </span>
    </Subtitle>
  </div>
</template>
