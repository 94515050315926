<template>
  <!-- ***** FAQ Area Start ***** -->
  <section id="faq">
    <div class="myContainer">


      <div class="row">
        <div class="faq-box">
        <div class="col-12">
          <!-- Heading Text  -->
          <div class="section-heading text-center">
            <h2>FREQUENTLY ASKED QUESTIONS</h2>
            <div class="line-title-center"></div>
            <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit amet, consectetur adipisicing incididunt.</p>
          </div>
        </div>
        <div class="col-12">
          <div id="accordion" role="tablist">

            <div class="card" v-for="index in 10" :key="index">
              <div class="card-header heading" role="tab">
                <h6 class="mb-0">
                  <a :href="'#collapse'+index" data-parent="#accordion" data-toggle="collapse">
                    {{ index }}. Sedeiusmod tempor inccsetetur aliquatraiy?
                  <i class="icon-arrow-down "></i>
                </a></h6>
              </div>

              <div :id="'collapse'+index" :class="index===1 ? 'collapse show':'collapse' ">
                <div class="card-body">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.
                </div>
              </div>
            </div>

          </div>
        </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script setup>

</script>

<style scoped>
#faq .card-header{
  background-color: white;
}
#faq .faq-box{
  box-shadow: 0 0 10px rgba(0 ,0 ,0 ,0.2);
  margin: 5%;
  border-radius: 15px;
}
#faq  a{
  color: #726a84;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#faq  a:hover, a:active, a:focus {
  outline: none;
  text-decoration: none;
  color: #00c2a5;
}

#faq .card{
  margin-bottom: 30px;
  border-width: 0 0 0 0;
  border-inline-start-width: 3px;
  border-style: solid;
  -webkit-border-image: -webkit-linear-gradient(#00c5a5, rgba(0, 120, 192, 0.93)) 1 100%;
  -o-border-image: -o-linear-gradient(#00c5a5, rgba(0, 120, 192, 0.93)) 1 100%;
  border-image: linear-gradient(#00c5a5, rgba(0, 120, 192, 0.93)) 1 100%;
}
#faq .card:lang(ar){
  margin-bottom: 30px;
  border-width: 0 0 0 0;
  border-inline-start-width: 3px;
  border-style: solid;
  -webkit-border-image: -webkit-linear-gradient(#00c5a5, rgba(0, 120, 192, 0.93)) 1 100%;
  -o-border-image: -o-linear-gradient(#00c5a5, rgba(0, 120, 192, 0.93)) 1 100%;
  border-image: linear-gradient(#00c5a5, rgba(0, 120, 192, 0.93)) 1 100%;
}
#faq .card i{
  float: right;
}

#faq .card i:lang(ar){
  float: left;
}

.faq-img{
  margin-bottom: 20px;
}

</style>