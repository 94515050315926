<template>
  <!-- ***** Why is the Best Section ***** -->
  <section id="whyisbest">
    <div class="myContainer">
      <div class="row">
        <div class="col-lg-5 ">
        <div class="img-padding-tb">
          <img src="@/assets/images/bg-img/app3.png" alt="">
        </div>
      </div>
      <div class="col-lg-6 ml-md-auto">
        <!-- Section Heading Area -->
        <div class="section-heading mb-4">
          <h2>Empire Business Landing Page For You</h2>
        </div>
        <p>Sed vehicula semper sapien, quis gravida massa cursus luctus. Cras at feugiat urna. Praesent tempus sed quam vel ornare. Quisque tempus orci pretium lorem euismod, a hendrerit odio viverra. In at viverra lacus, a blandit urna. Aenean semper leo at nisl malesuada, rutrum sodales ipsum posuere. Duis sit amet sapien dolor. Vivamus luctus viverra leo, vel ultrices eros eleifend . </p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis rhoncus ante, eget molestie dolor. Duis tellus magna, malesuada vitae velit in, ultrices fringilla metus. Nulla faci.</p>
        <div class="app-download-area">
          <div class="app-download-btn">
            <!-- Google Store Btn -->
            <a href="#">
              <i class="fab fa-google-play"></i>
              <p class="mb-0">Google Store</p>
            </a>
          </div>
          <div class="app-download-btn">
            <!-- Apple Store Btn -->
            <a href="#">
              <i class="fab fa-apple"></i>
              <p class="mb-0">Apple Store</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>


<script setup>
</script>
<style scoped>

.app-download-area {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 1;
  flex-basis: 1px;
  margin-top: 45px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}

.app-download-btn a {
  border: 1px solid #41c9a2;
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 30px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.app-download-btn.active a,
.app-download-btn a:hover {
  border-color: transparent;
  background-color: #41c9a2;
}

.app-download-btn a i {
  color: #41c9a2;
  font-size: 24px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}

.app-download-btn a p {
  font-size: 16px;
  line-height: 1;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  color: #41c9a2;
}

.app-download-btn a p span {
  font-size: 12px;
  display: block;
}

/*.app-download-btn:first-child {
  margin-right: 20px;
}*/

.app-download-btn a:hover i,
.app-download-btn.active a i,
.app-download-btn.active a p,
.app-download-btn a:hover p {
  color: #fff;
}


#about .feature-single{
  margin-bottom: 30px;
}

#about .feature-single h4{
  padding-top: 1rem;
}

#about .feature-left, .feature-right{

}

#about.feature-left .feature-icon {
  float: right;
  margin-left: 2rem;
}

#about .feature-right .feature-icon {
  float: left;
  margin-right: 2rem;
}

#about .feature-icon {
  width: 6rem;
  height: 6rem;
  color: #41c9a2;
  text-align: center;
  margin-bottom: 1rem;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  -webkit-transition: all 1s;
  transition: all 1s;
}

#about .feature-icon i {
  font-size: 30px;
  line-height: 6rem;
}

#about .feature-icon:hover {
  color: #41c9a275;
}

.feature-img1 {
  min-width: 100%;
  height: auto;
  padding-top: 70px;
}
.app-download-btn a{
  margin-inline-end: 15px;
}
</style>