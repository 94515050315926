<template>
  <div class="engz">
    <WaitApp :title="title" :description="description" />
    <SoonApp />
  </div>
</template>

<script setup>
import WaitApp from "@/components/sections/WaitApp";
import SoonApp from "@/components/sections/SoonApp";
import { ref } from "vue";
const title = ref("Engz");
const description = ref("Soon the service will be launched, wait for us.");
</script>

<style scoped></style>
