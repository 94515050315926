import type { BaseFeature, SimpleIconFeature } from '@/types'
export const commerceAppFeatures: BaseFeature[] = [
  {
    title: 'Simple to use',
    text: 'Our app is very intuitive and simple to use, even for tech-savy users.',
  },
  {
    title: '90% Profit',
    text: 'We only take a small part of your earnings every month, for the best.',
  },
  {
    title: 'Secure',
    text: 'Our payment gateways integrations are very reliable, secure and private.',
  },
]

export const salesFeatures: SimpleIconFeature[] = [
  {
    title: 'Sell anywhere',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:storefront-outline',
  },
  {
    title: 'Sell software',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:save-outline',
  },
  {
    title: 'Sell art',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:brush-outline',
  },
  {
    title: 'Sell subscriptions',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:calendar-outline',
  },
]

export const growthFeatures: SimpleIconFeature[] = [
  {
    title: 'admin.3.f.t1',
    text: 'admin.3.f.a1',
    icon: 'ion:ios-git-network',
  },
  {
    title: 'admin.3.f.t2',
    text: 'admin.3.f.a2',
    icon: 'ion:create-outline',
  },
  {
    title: 'admin.3.f.t3',
    text: 'admin.3.f.a3',
    icon: 'ion:magnet-outline',
  },
  {
    title: 'admin.3.f.t4',
    text: 'admin.3.f.a4',
    icon: 'ion:send',
  },
]
export const StudentFeatures: SimpleIconFeature[] = [
  {
    title: 'student.7.t1',
    text: 'student.7.a1',
    icon: 'ion:file-tray-stacked-outline',
  },
  {
    title: 'student.7.t2',
    text: 'student.7.a2',
    icon: 'ion:calendar-number-outline',
  },
  {
    title: 'student.7.t3',
    text: 'student.7.a3',
    icon: 'ion:stats-chart-outline',
  },
  {
    title: 'student.7.t4',
    text: 'student.7.a4',
    icon: 'ion:reader-outline',
  },
]
export const noteFeatures: SimpleIconFeature[] = [
  {
    title: 'admin.5.f.t1',
    text: 'admin.5.f.a1',
    icon: 'ion:notifications-circle',
  },
  {
    title: 'admin.5.f.t2',
    text: 'admin.5.f.a2',
    icon: 'ion:notifications-off-circle-sharp',
  },
]
export const bagFeatures: SimpleIconFeature[] = [
  {
    title: 'admin.6.t1',
    text: 'admin.6.a1',
    icon: 'ion:archive',
  },
  {
    title: 'admin.6.t2',
    text: 'admin.6.a2',
    icon: 'ion:person-circle-sharp',
  },
  {
    title: 'admin.6.t3',
    text: 'admin.6.a3',
    icon: 'ion:extension-puzzle-sharp',
  },
]
export const studentFeatures: SimpleIconFeature[] = [
  {
    title: 'admin.7.t1',
    text: 'admin.7.a1',
    icon: 'ion:md-home',
  },
  {
    title: 'admin.7.t2',
    text: 'admin.7.a2',
    icon: 'ion:analytics',
  },
  {
    title: 'admin.7.t3',
    text: 'admin.7.a3',
    icon: 'ion:ban',
  },
  {
    title: 'admin.7.t4',
    text: 'admin.7.a4',
    icon: 'ion:chatbubble-working',
  },
]
export const postsFeatures: SimpleIconFeature[] = [
  {
    title: 'admin.8.t1',
    text: 'admin.8.a1',
    icon: 'ion:md-home',
  },
  {
    title: 'admin.8.t2',
    text: 'admin.8.a2',
    icon: 'ion:analytics',
  },
  {
    title: 'admin.8.t3',
    text: 'admin.8.a3',
    icon: 'ion:ban',
  },
  {
    title: 'admin.8.t4',
    text: 'admin.8.a4',
    icon: 'ion:chatbubble-working',
  },
]
export const quickFeatures: SimpleIconFeature[] = [
  {
    title: 'admin.9.t1',
    text: 'admin.9.a1',
    icon: 'ion:md-home',
  },
  {
    title: 'admin.9.t2',
    text: 'admin.9.a2',
    icon: 'ion:analytics',
  },
  {
    title: 'admin.9.t3',
    text: 'admin.9.a3',
    icon: 'ion:ban',
  },
  {
    title: 'admin.9.t4',
    text: 'admin.9.a4',
    icon: 'ion:chatbubble-working',
  },
]
export const profileFeatures: SimpleIconFeature[] = [
  {
    title: 'student.11.t1',
    text: 'student.11.a1',
    icon: 'ion:reload-circle-sharp',
  },
  {
    title: 'student.11.t2',
    text: 'student.11.a2',
    icon: 'ion:md-home',
  },
  {
    title: 'student.11.t3',
    text: 'student.11.a3',
    icon: 'ion:analytics-sharp',
  },
]
export const smartFeatures: SimpleIconFeature[] = [
  {
    title: 'admin.10.t1',
    text: 'admin.10.a1',
    icon: 'ion:social-buffer-outline',
  },
  {
    title: 'admin.10.t2',
    text: 'admin.10.a2',
    icon: 'ion:social-buffer-outline',
  },
]

export const testFeatures: SimpleIconFeature[] = [
  {
    title: 'admin.4.f.t1',
    text: 'admin.4.f.a1',
    icon: 'ion:accessibility',
  },
  {
    title: 'admin.4.f.t2',
    text: 'admin.4.f.a2',
    icon: 'ion:accessibility',
  },
]
export const businessFeatures: SimpleIconFeature[] = [
  {
    title: 'Email marketing',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:mail-unread-outline',
  },
  {
    title: 'Bundles & upsells',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:sparkles-outline',
  },
  {
    title: 'Lead magnets',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:magnet-outline',
  },
  {
    title: 'Discount codes',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:gift-outline',
  },
]

export const managementFeatures: SimpleIconFeature[] = [
  {
    title: 'pages.success.Student1Feature.exercises',
    text: 'pages.success.Student1Feature.text1',
    icon: 'ion:construct-outline',
  },
  {
    title: 'pages.success.Student1Feature.chats',
    text: 'pages.success.Student1Feature.text2',
    icon: 'ion:speedometer-outline',
  },
  {
    title: 'pages.success.Student1Feature.schedule',
    text: 'pages.success.Student1Feature.text3',
    icon: 'ion:reader-outline',
  },
  {
    title: 'pages.success.Student1Feature.library',
    text: 'pages.success.Student1Feature.text4',
    icon: 'ion:document-attach-outline',
  },
]

export const testimonials = [
  {
    icon: 'et:briefcase',
    text: 'pages.testimonials.text1',
    testifier: {
      name: 'pages.testimonials.exercises',
      picture: '/public/assets/illustrations/team/1.svg',
      role: 'Foundation director',
    },
  },
  {
    icon: 'et:adjustments',
    text: 'pages.testimonials.text2',
    testifier: {
      name: 'pages.testimonials.chats',
      picture: '/public/assets/illustrations/team/1.svg',
      role: 'Student',
    },
  },
  {
    icon: 'et:trophy',
    text: 'pages.testimonials.text3',
    testifier: {
      name: 'pages.testimonials.schedule',
      picture: '/public/assets/illustrations/team/2.svg',
      role: 'Engineer',
    },
  },
  {
    icon: 'et:lightbulb',
    text: 'pages.testimonials.text4',
    testifier: {
      name: 'pages.testimonials.library',
      picture: '/public/assets/illustrations/team/4.svg',
      role: '',
    },
  },
  {
    icon: 'et:lightbulb',
    text: 'pages.testimonials.text5',
    testifier: {
      name: 'pages.testimonials.chat',
      picture: '/public/assets/illustrations/team/4.svg',
      role: 'Mangment Team',
    },
  },
  {
    icon: 'et:layers',
    text: 'pages.testimonials.text6',
    testifier: {
      name: 'pages.testimonials.cha',
      picture: '/public/assets/illustrations/team/5.svg',
      role: 'Chemical',
    },
  },
  {
    icon: 'et:browser',
    text: 'pages.testimonials.text7',
    testifier: {
      name: 'pages.testimonials.cha',
      picture: '/public/assets/illustrations/team/6.svg',
      role: 'Teacher',
    },
  },
  {
    icon: 'et:camera',
    text: 'pages.testimonials.text8',
    testifier: {
      name: 'pages.testimonials.ch',
      picture: '/public/assets/illustrations/team/5.svg',
      role: 'professor',
    },
  },
  {
    icon: 'et:compass',
    text: 'pages.testimonials.text9',
    testifier: {
      name: 'pages.testimonials.c',
      picture: '/public/assets/illustrations/team/8.svg',
      role: 'College student',
    },
  },
  {
    icon: 'et:documents',
    text: 'pages.testimonials.text10',
    testifier: {
      name: 'pages.testimonials.chatss',
      picture: '/public/assets/illustrations/team/1.svg',
      role: 'programs developer',
    },
  },
  {
    icon: 'et:chat',
    text: 'pages.testimonials.text11',
    testifier: {
      name: 'pages.testimonials.chatsss',
      picture: '/public/assets/illustrations/team/3.svg',
      role: 'Founder of educational institutions',
    },
  },
]
export const sadeemFeature = [
  {
    icon: 'et:briefcase',
    text: 'pages.sadeemFeature.text1',
    testifier: {
      name: '',
      picture: '',
      role: '',
    },
  },
  {
    icon: 'et:adjustments',
    text: 'pages.sadeemFeature.text2',
    testifier: {
      name: '',
      picture: '',
      role: '',
    },
  },
  {
    icon: 'et:trophy',
    text: 'pages.sadeemFeature.text3',
    testifier: {
      name: '',
      picture: '',
      role: '',
    },
  },
  {
    icon: 'et:lightbulb',
    text: 'pages.sadeemFeature.text4',
    testifier: {
      name: '',
      picture: '',
      role: '',
    },
  },
]
export const posts = [
  {
    id: 0,
    image: require('@/assets/p6/ERP.png'),
    title: 'pages.TechnicalServices.posts.post1',
    text: 'pages.TechnicalServices.posts.text1',
    categories: [
      {
        name: 'ERP',
      },
      {
        name: 'design',
      },
      {
        name: 'Profit',
      },
    ],
    date: 'March 17th, 2022',
    duration: 2,
  },
  {
    id: 1,
    image:require('@/assets/p6/learn.png'),
    title: 'pages.TechnicalServices.posts.post2',
    text: 'pages.TechnicalServices.posts.text2',
    categories: [
      {
        name: 'learn',
      },
      {
        name: 'Students',
      },
      {
        name: 'School',
      },
    ],
    date: 'March 17th, 2022',
    duration: 2,
  },
  {
    id: 2,
    image: require('@/assets/p6/name.png'),
    title: 'pages.TechnicalServices.posts.post3',
    text: 'pages.TechnicalServices.posts.text3',
    categories: [
      {
        name: 'Mangment',
      },
      {
        name: 'Sales',
      },
      {
        name: 'Control',
      },
    ],
    date: 'March 17th, 2022',
    duration: 2,
  },
]

export const customerLogos = [
  {
    id: 0,
    logo: '/assets/brands/kromo.svg',
  },
  {
    id: 1,
    logo: '/assets/brands/proactive.svg',
  },
  {
    id: 2,
    logo: '/assets/brands/tribe.svg',
  },
  {
    id: 3,
    logo: '/assets/brands/infinite.svg',
  },
  {
    id: 4,
    logo: '/assets/brands/grubspot.svg',
  },
]

export const faq = {
  left: [
    {
      title: 'How much does it cost?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
    {
      title: 'Does it really work anywhere?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
    {
      title: 'How does it differ from other tools?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
  ],
  right: [
    {
      title: 'How does it work?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
    {
      title: 'Can I try it for free?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
    {
      title: 'How many agents are allowed?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
  ],
}
