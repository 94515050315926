<template>
  <div class="error-view">
    <MainContainerAPP :title="title" :description="description" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import MainContainerAPP from "@/components/sections/MainContainer";
const title = ref("404");
const description = ref("Page not found sorry");
</script>
