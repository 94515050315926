<template>
<section id="BranchesTreeApp">
  <div class="myContainer">
    <div class="row" data-aos="fade-up">
      <div class="col-12">
        <!-- Heading Text -->
        <div class="section-heading text-center">
          <h2>Branches</h2>
          <div class="line-title-center"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul id="myUL">
          <li><span class="title-branch">Ezar Group Branches</span>
            <ul class="nested active">

              <li><span >Iraq</span>
                <ul class="nested active">

                  <li>
                    <div class="info-city caret"><i class="fa fa-map-marker-alt"></i> <p>Baghdad</p></div>
                    <ul class="nested">
                      <li>
                        <div class="info-bran">

                          <div class="info-center"><i class="fa fa-city"></i> <p>Center name</p></div>
                          <div class="info-phone"><i class="fa fa-phone"></i> <p>+96499999996</p></div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <div class="info-city caret"><i class="fa fa-map-marker-alt"></i> <p>Basra</p></div>
                    <ul class="nested">
                      <li>
                        <div class="info-bran">

                          <div class="info-center"><i class="fa fa-city"></i> <p>Center name</p></div>
                          <div class="info-phone"><i class="fa fa-phone"></i> <p>+96499999996</p></div>
                        </div>
                      </li>
                    </ul>
                  </li>



                </ul>
              </li>




              <li><span >Syria</span>
                <ul class="nested active">

                  <li>
                    <div class="info-city caret"><i class="fa fa-map-marker-alt"></i> <p>Damascus</p></div>
                    <ul class="nested">
                      <li>
                        <div class="info-bran">

                          <div class="info-center"><i class="fa fa-city"></i> <p>Center name</p></div>
                          <div class="info-phone"><i class="fa fa-phone"></i> <p>+963999999</p></div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <div class="info-city caret"><i class="fa fa-map-marker-alt"></i> <p>Homus</p></div>
                    <ul class="nested">
                      <li>
                        <div class="info-bran">

                          <div class="info-center"><i class="fa fa-city"></i> <p>Center name</p></div>
                          <div class="info-phone"><i class="fa fa-phone"></i> <p>+963999999</p></div>
                        </div>
                      </li>
                    </ul>
                  </li>



                </ul>
              </li>


              <li><span >Lebanon</span>
                <ul class="nested active">

                  <li>
                    <div class="info-city caret"><i class="fa fa-map-marker-alt"></i> <p>Beirut</p></div>
                    <ul class="nested">
                      <li>
                        <div class="info-bran">

                          <div class="info-center"><i class="fa fa-city"></i> <p>Center name</p></div>
                          <div class="info-phone"><i class="fa fa-phone"></i> <p>+962777777</p></div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <!--                    <span class="caret"></span>-->
                    <div class="info-city caret"><i class="fa fa-map-marker-alt"></i> <p>Baalbek</p></div>
                    <ul class="nested">
                      <li>
                        <div class="info-bran">

                          <div class="info-center"><i class="fa fa-city"></i> <p>Center name</p></div>
                          <div class="info-phone"><i class="fa fa-phone"></i> <p>+962777777</p></div>
                        </div>
                      </li>
                    </ul>
                  </li>



                </ul>
              </li>





              <li><span >UAE</span>
                <ul class="nested active">

                  <li>
                    <div class="info-city caret"><i class="fa fa-map-marker-alt"></i> <p>Abu Dhabi</p></div>
                    <ul class="nested">
                          <li>
                    <div class="info-bran">

                      <div class="info-center"><i class="fa fa-city"></i> <p>Center name</p></div>
                      <div class="info-phone"><i class="fa fa-phone"></i> <p>+962777777</p></div>
                    </div>
                  </li>
                    </ul>
                  </li>

                  <li>
                    <!--                    <span class="caret"></span>-->
                    <div class="info-city caret"><i class="fa fa-map-marker-alt"></i> <p>Dubai</p></div>
                    <ul class="nested">
                      <li>
                        <div class="info-bran">

                          <div class="info-center"><i class="fa fa-city"></i> <p>Center name</p></div>
                          <div class="info-phone"><i class="fa fa-phone"></i> <p>+962777777</p></div>
                        </div>
                      </li>
                    </ul>
                  </li>



                </ul>
              </li>

              <!--              <li><span class="caret">Tea</span>
                              <ul class="nested">
                                <li>Black Tea</li>
                                <li>White Tea</li>
                                <li><span class="caret">Green Tea</span>
                                  <ul class="nested">
                                    <li>Sencha</li>
                                    <li>Gyokuro</li>
                                    <li>Matcha</li>
                                    <li>Pi Lo Chun</li>
                                  </ul>
                                </li>
                              </ul>
                            </li>-->
            </ul>
          </li>
        </ul>

      </div>
    </div>
    </div>
</section>
</template>

<script setup>


    var toggler = document.getElementsByClassName("caret");
    var i;

    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function() {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      });
  }

</script>

<style scoped>

#BranchesTreeApp{
padding-bottom: 50px;
  /* background: #F1EFEB; */
}

#BranchesTreeApp li{
  color: var(--main-color);
  margin-bottom: 30px;
  font-size: 18px;
}

#BranchesTreeApp li span{
  color: var(--main-color);
  font-size: 24px;
}

#BranchesTreeApp ul, #myUL {
  list-style-type: none;
  margin-bottom: 20px;
  margin-top: 20px;
}

#myUL {
  margin: 0;
  padding: 0;
}

#BranchesTreeApp .caret {
  cursor: pointer;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  transition: all ease 0.5s;

}
.is-dark #BranchesTreeApp .caret p{
  color:var(--text-color)
}
#BranchesTreeApp .caret:hover{
color: var(--main-color);
  transition: all ease-out 0.4s;
  font-size: 24px;
}

/*
#BranchesTreeApp .caret::before {
  content: "\25B6";
  color: black;
  font-size: 18px;
  display: inline-block;
  margin-right: 6px;
}
*/

/*
#BranchesTreeApp .caret-down:before {
  -ms-transform: rotate(90deg); !* IE 9 *!
  -webkit-transform: rotate(90deg); !* Safari *!
  transform: rotate(90deg);
  font-size: 18px;
}
*/

#BranchesTreeApp .nested {
  display: none;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 30px;
  transition: all ease 10s;
}

#BranchesTreeApp .active {
  display: block;
  height: 100%;
  transition: all ease 10s;
}

.info-bran{
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  justify-content: flex-start;
  margin-inline-start: 30px;
}

@media (max-width: 800px) {
  .info-bran{
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    justify-content: flex-start;
    margin-inline-start: 0px;
  }
}
.info-city{
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  justify-content: flex-start;
  margin: 10px;
}
.info-center{
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  justify-content: center;
  margin: 10px;
}
.info-phone{
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  justify-content: center;
  margin: 10px;
}
.info-bran p{
  margin: 0;
}
.info-bran i{
  margin-inline-end: 5px;
  font-size: 24px;
}

.info-city i{
  margin-inline-end: 10px;
  margin-inline-start: 10px;
  font-size: 24px;
}
.myUL.title-branch{
  font-weight: 500;
  color: #636d75;
  font-size: 24px;
  margin-bottom: 30px;
}
</style>