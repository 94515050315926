<template>
  <!-- ***** App Screenshots Area Start ***** -->

  <section class="app-screenshots-area bg-white" id="screenshot">
    <div class="myContainer">
      <div class="row">
        <div class="col-12">
          <!-- Heading Text -->
          <div class="section-heading text-center">
            <h2 class="title-screen">App Screenshoots</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <swiper
            :centeredSlides="true"
            :loop="true"
            :speed="500"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
            :clickable="true"
            :slidesPerView="3"
            :breakpoints="{
              '820': {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              '1024': {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }"
            :spaceBetween="10"
            :pagination="{ clickable: true }"
            :modules="modules"
            class="mySwiper2"
          >
            <swiper-slide
              ><img src="@/assets/images/scr-img/app-1.png"
            /></swiper-slide>
            <swiper-slide
              ><img src="@/assets/images/scr-img/app-2.png"
            /></swiper-slide>
            <swiper-slide
              ><img src="@/assets/images/scr-img/app-3.png"
            /></swiper-slide>
            <swiper-slide
              ><img src="@/assets/images/scr-img/app-4.png"
            /></swiper-slide>
            <swiper-slide
              ><img src="@/assets/images/scr-img/app-5.png"
            /></swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
  <!-- ***** App Screenshots Area End *****====== -->
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import { Pagination, Autoplay } from "swiper";
import "swiper/css/pagination";
import { ref } from "vue";
//  Pagination.value = {
//   el: ".pagination",
//   clickable: "true",
//   renderBullet: function (index, className) {
//     return '<span class="' + className + '">' + (index + 1) + "</span>";
//   },
// }
const modules = ref([Pagination, Autoplay]);
</script>

<style scoped>
#screenshot {
  padding-bottom: 50px;
}

.app_screenshots_slides {
  margin-bottom: 30px;
}

.app_screenshots_slides .single-shot {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

.app_screenshots_slides .center .single-shot {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.carousel__pagination-button--active {
  background-color: #41c9a2;
}

.app-screenshots-area .owl-dot {
  height: 11px;
  width: 11px;
  border: 3px solid #41c9a2;
  border-radius: 50%;
  display: inline-block;
  margin: 0 6px;
  margin-top: 50px;
}

.app-screenshots-area .owl-dot.active {
  background-color: #41c9a2;
  border: transparent;
}

.app-screenshots-area .owl-dots {
  height: 100%;
  width: 100%;
  text-align: center;
}

.title-screen {
  text-align: center;
  padding-bottom: 35px;
  position: relative;
  display: inline-block;
  font-weight: 300;
  color: #636d75;
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;
}

.title-screen::before {
  content: "";
  background: red;
  width: 70px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  margin: auto;
}

.title-screen:after {
  content: "";
  background: var(--main-color);
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}
</style>
