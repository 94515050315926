<script setup lang="ts">
import { defineProps } from "vue";
import type { SimpleIconFeature } from '@/types'

export interface BoxedFeaturesProps {
  features: SimpleIconFeature[]
}

const props = defineProps<BoxedFeaturesProps>()
</script>

<template>
  <div class="py-6 ">
    <div class="boxed-features">
      <div class="Card light-bordered hover-inset section-lightness">
        <div
          v-for="(feature, index) in props.features.slice(0, 8)"
          :key="index"
          class="flex-cell"
          :class="[
            index <= 3 && 'is-bordered',
            index === 3 && 'no-border-edge',
            index === 7 && 'no-border-edge',
          ]"
        >
          <i aria-hidden="true" class="iconify" :data-icon="feature.icon"></i>
          <h3>{{ $t(feature.title) }}</h3>
          <p>{{ $t(feature.text) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.boxed-features {
  .Card {
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    border-radius: 1rem;
    border: 1px solid var(--card-border-color);
    background: var(--card-bg-color);

    .flex-cell {
      width: 25%;
      border-inline-end: 1px solid var(--card-border-color);
      text-align: center;
      padding: 24px 16px;

      &.is-bordered {
        border-bottom: 1px solid var(--card-border-color);
      }

      &.no-border-edge {
        border-inline-end: none;
      }

      i,
      .iconify {
        font-size: 2rem;
        color: var(--primary);
      }

      h3 {
        font-family: var(--font) !important;
        font-weight: 500;
        color: var(--title-color);
        margin-bottom:6px
      }

      p {
        font-family: var(--font) !important;
        font-size: 0.9rem;
        color: var(--light-text);
        max-width: 200px;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 767px) {
  .boxed-features {
    .Card {
      .flex-cell {
        width: 100%;
        border-inline-end: none;
        border-bottom: 1px solid var(--card-border-color);

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .boxed-features {
    .Card {
      padding: 1rem;
    }
  }
}
</style>
