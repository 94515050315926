<template>
  <div class="myContainer">
    <div class="row">
      <div class="col-md-12">
        <!-- Heading Text  -->
        <div class="section-heading text-center">
          <h2>{{ props.title }}</h2>
          <div class="line-title-center"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 d-lg-flex align-items-center mb-4">
        <div>
          <h1>Where do you</h1>
          <h1 class="mb-4" style="color: #00c9a4;">want to eat?</h1>
          <p>The previous student design project was the To Do List application product factory, The counter examples are the result of the efforts of the students and their teamwork in the first course.</p>
        </div>
      </div>
      <div class="col-12 col-lg-6 position-relative">
        <div class="swiper mySwiperRightAdmin">
          <div class="swiper-wrapper">

            <div class="swiper-slide">
              <div class="row ">

                <div class="col-12">
                  <div class="d-flex d-sm-flex justify-content-center parent_iter3">
                    <div class="child1_iter3"></div>
                    <div class="child2_iter3">
                      <img src="@/assets/images/dashboard.jpg" width="100%" height="100%">
                    </div>
                  </div>
                </div>


              </div>
            </div>


          </div>
          <div class="d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center child3_left_iter3"><i class="fas fa-angle-left"></i></div>
          <div class="d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center child3_right_iter3"><i class="fas fa-angle-right"></i></div>
        </div>
      </div>

    </div>


  </div>
<!--    <div class="swiper mySwiper">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <div class="row position-relative">
            <div class="col-12 col-lg-6 d-lg-flex align-items-lg-center mb-4">
              <div>
                <h1>Where do you</h1>
                <h1 class="mb-4" style="color: #00c9a4;">want to eat?</h1>
                <p>The previous student design project was the To Do List application product factory, The counter examples are the result of the efforts of the students and their teamwork in the first course.</p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex d-sm-flex justify-content-center parent_iter3">
                <div class="child1_iter3"></div>
                <div class="child2_iter3">
                  <img src="images/dashboard.jpg" width="100%" height="100%">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="row position-relative">
            <div class="col-12 col-lg-6 d-lg-flex align-items-lg-center mb-4">
              <div>
                <h1>Where do you</h1>
                <h1 class="mb-4" style="color: #00c9a4;">want to eat?</h1>
                <p>The previous student design project was the To Do List application product factory, The counter examples are the result of the efforts of the students and their teamwork in the first course.</p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex d-sm-flex justify-content-center parent_iter3">
                <div class="child1_iter3"></div>
                <div class="child2_iter3">
                  <img src="images/dashboard.jpg" width="100%" height="100%">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center child3_left_iter3"><i class="fas fa-angle-left"></i></div>
      <div class="d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center child3_right_iter3"><i class="fas fa-angle-right"></i></div>
    </div>-->

</template>

<script setup>
import Swiper, { Navigation ,Autoplay } from 'swiper';
Swiper.use([Navigation]);
Swiper.use([Autoplay]);
import {defineProps,onMounted} from "vue";

const props= defineProps(['title'])
  onMounted(()=>{
    var mySwiper = new Swiper(".mySwiperRightAdmin", {

      loop:true,
      speed:1000,
      autoplay: {
        delay: 40000,

      },
      navigation: {
        nextEl: ".child3_right_iter3",
        prevEl: ".child3_left_iter3",
      },
    });
    return mySwiper;
  })
</script>

<style scoped>

.parent_iter3 {
  width: 100%;
  height: 100%;
  min-height: 612px;
  position: relative;
  border-radius: 30px;
}

.child1_iter3 {
  width: 100%;
  height: 60%;
  border-radius: 30px;
  background-color: #F5F5F5;
  position: absolute;
  top: 0px;
  right: 0px;
}

.child2_iter3 {
  width: 100%;
  height: 75%;
  border-radius: 30px;
  position: absolute;
  top: 30px;
  left: 0;
  border: 0px;
/*  box-shadow: 2px 2px 5px #e4e3e3;*/
}

.child2_iter3 img {
  border-radius: 30px;
  border: 0px;
  min-height: 100%;
}





.child3_left_iter3 {
  width: 50px;
  height: 30px;
  position: absolute;
  bottom: 10%;
  left: 10%;
  border-radius: 10px;
  color: white;
  background-color: rgba(67, 206, 162, 0.95);
  box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
  cursor: pointer;
  font-size: inherit;
  z-index: 1;
}

@media (min-width:850px) {
  .child3_left_iter3 {
    width: 50px;
    height: 30px;
    position: absolute;
    bottom: 10%;
    left: 10%;
    border-radius: 10px;
    color: white;
    background-color: rgba(67, 206, 162, 0.95);
    box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
    cursor: pointer;
    font-size: inherit;
    z-index: 1;
  }
}

.child3_right_iter3 {
  width: 50px;
  height: 30px;
  position: absolute;
  bottom: 10%;
  right: 10%;
  border-radius: 10px;
  color: white;
  background-color: rgba(67, 206, 162, 0.95);
  box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
  cursor: pointer;
  font-size: initial;
  z-index: 1;
}

@media (min-width:850px) {
  .child3_right_iter3 {
    width: 50px;
    height: 30px;
    position: absolute;
    bottom: 10%;
    right: 10%;
    border-radius: 10px;
    color: white;
    background-color: rgba(67, 206, 162, 0.95);
    box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
    cursor: pointer;
    font-size: initial;
    z-index: 1;
  }
}




.child3_left_iter3:lang(ar) {
  width: 50px;
  height: 30px;
  position: absolute;
  bottom: 10%;
  left: 10%;
  border-radius: 10px;
  border: 0px;
  z-index: 1;
  color: white;
  background-color: rgba(67, 206, 162, 0.95);
  box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
  cursor: pointer;
}

@media (min-width:850px) {
  .child3_left_iter3 {
    width: 50px;
    height: 30px;
    position: absolute;
    bottom: 10%;
    left: 10%;
    border-radius: 10px;
    color: white;
    background-color: rgba(67, 206, 162, 0.95);
    box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
    cursor: pointer;
    font-size: inherit;
    z-index: 1;
  }
}

.child3_right_iter3:lang(ar) {
  width: 50px;
  height: 30px;
  position: absolute;
  bottom: 10%;
  right: 10%;
  border-radius: 10px;
  z-index: 1;
  color: white;
  background-color: rgba(67, 206, 162, 0.95);
  box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
  cursor: pointer;
}

@media (min-width:850px) {
  .child3_right_iter3 {
    width: 50px;
    height: 30px;
    position: absolute;
    bottom: 10%;
    right: 10%;
    border-radius: 10px;
    color: white;
    background-color: rgba(67, 206, 162, 0.95);
    box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
    cursor: pointer;
    z-index: 1;
  }
}

</style>