<template>
  <section id="blog" class="section">
    <!-- Container Starts -->
    <div class="myContainer">
      <div class="row" data-aos="fade-up">
        <div class="col-12">
          <!-- Heading Text -->
          <div class="section-heading text-center">
            <h2>Latest Blogs </h2>
            <div class="line-title-center"></div>
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col-lg-4 col-md-4 col-xs-12 blog-item" data-aos="fade-up" data-aos-delay="300">
          <!-- Blog Item Starts -->
          <div class="blog-item-area "  >
            <div class="blog-item-img">
              <a href="#">
                <img src="@/assets/images/blog-img/1.jpg" alt="">
              </a>
            </div>
            <div class="blog-item-text">
              <h3>
                <a href="#">How often should you tweet?</a>
              </h3>
              <div class="meta-tags">
                <span class="date"><i class="lnr lnr-calendar-full"></i>on Mar 23, 2018</span>
                <span class="comments"><a href="#"><i class="lnr lnr-bubble"></i> 24 Comments</a></span>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
              </p>
              <a href="#" class="btn btn-blog">Read More</a>
            </div>
          </div>
          <!-- Blog Item Wrapper Ends-->
        </div>

        <div class="col-lg-4 col-md-4 col-xs-12 blog-item" data-aos="fade-up"  data-aos-delay="600">
          <!-- Blog Item Starts -->
          <div class="blog-item-area "  >
            <div class="blog-item-img">
              <a href="#">
                <img src="@/assets/images/blog-img/2.jpg" alt="">
              </a>
            </div>
            <div class="blog-item-text">
              <h3>
                <a href="#">Content is still king</a>
              </h3>
              <div class="meta-tags">
                <span class="date"><i class="lnr lnr-calendar-full"></i>on Mar 29, 2018</span>
                <span class="comments"><a href="#"><i class="lnr lnr-bubble"></i> 24 Comments</a></span>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
              </p>
              <a href="#" class="btn btn-blog">Read More</a>
            </div>
          </div>
          <!-- Blog Item Wrapper Ends-->
        </div>

        <div class="col-lg-4 col-md-4 col-xs-12 blog-item" data-aos="fade-up"  data-aos-delay="900">
          <!-- Blog Item Starts -->
          <div class="blog-item-area"  >
            <div class="blog-item-img">
              <a href="#">
                <img src="@/assets/images/blog-img/3.jpg" alt="">
              </a>
            </div>
            <div class="blog-item-text">
              <h3>
                <a href="#">Social media at work</a>
              </h3>
              <div class="meta-tags">
                <span class="date"><i class="lnr lnr-calendar-full"></i>on Mar 21, 2018</span>
                <span class="comments"><a href="#"><i class="lnr lnr-bubble"></i> 24 Comments</a></span>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
              </p>
              <a href="#" class="btn btn-blog">Read More</a>
            </div>
          </div>
          <!-- Blog Item Wrapper Ends-->
        </div>
      </div>
    </div>
  </section>
  <!-- blog Section End -->

</template>

<script setup>

</script>

<style scoped>

#blog {
  padding-bottom: 15px;
  background: var(--section-color);
}
#blog .blog-item-area {
  background: var(--section-color2);
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: transform 0.2s linear, -webkit-box-shadow 0.2s linear;
  -moz-transition: transform 0.2s linear, -moz-box-shadow 0.2s linear;
  transition: transform 0.2s linear, box-shadow 0.2s linear;
}
#blog .blog-item-area:hover {
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
}
#blog .blog-item-area:hover .blog-item-img:before {
  opacity: 1;
  height: 100%;
  width: 100%;
}
#blog .blog-item-img {
  position: relative;
}
#blog .blog-item-img img {
  width: 100%;
}
#blog .blog-item-img:before {
  width: 50%;
  height: 50%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(65, 201, 162, 0.2);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
#blog .blog-item-text {
  border: 1px solid var(--section-color);
  padding: 20px;
}
#blog .blog-item-text .meta-tags {
  margin-bottom: 10px;
}
#blog .blog-item-text .meta-tags span {
  color: #999;
  margin-right: 10px;
}
#blog .blog-item-text .meta-tags span i {
  margin-right: 5px;
}
#blog .blog-item-text .meta-tags span a {
  color: #999;
  float: right;
}
#blog .blog-item-text .meta-tags span a:hover {
  color: #2a3855;
}
#blog .blog-item-text h3 {
  line-height: 26px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
#blog .blog-item-text h3 a {
  color: #41c9a2;
}
#blog .blog-item-text h3 a:hover {
  color: #2a3855;
}
#blog .blog-item-text p {
  line-height: 25px;
}

.btn-blog {
  color: #fff;
  border: 1px solid #41c9a2;
  background-color: #41c9a2;
  border-radius: 0;
  min-width: 116px;
}

.btn-blog:hover {
  color: #41c9a2;
  background-color: transparent;
  border: 1px solid #41c9a2;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

</style>