import HomeView from "@/pages/Home/HomeView.vue";
import ErrorView from "@/pages/Home/ErrorView.vue";
import ServicesView from "@/pages/Services/views/ServicesView";
import ContactView from "@/pages/Contact/views/ContactView";
import AboutView from "@/pages/About/views/AboutView";
import AgentsView from "@/pages/Agents/views/AgentsView";
import B2BStoreView from "@/pages/B2BSTORE/views/B2BStoreView";
import BranchesView from "@/pages/Branches/views/BranchesView";
import EngzView from "@/pages/ENGZ/views/EngzView";
import EventsView from "@/pages/Events/views/EventsView";
import IterLearningView from "@/pages/IterLearning/views/IterLearningView";
import JobView from "@/pages/Jobs/views/JobView";
import NewsView from "@/pages/News/views/NewsView";
import CommonQuestionsView from "@/pages/CommonQuestions/views/CommonQuestionsView";
import TechnicalView from "@/pages/TechnicalSupport/views/TechnicalView";
import IterView from "@/pages/Iter/views/IterView";
import IterStudentView from "@/pages/Iter/views/IterStudentView";
import IterTeacherView from "@/pages/Iter/views/IterTeacherView";
import IterAdminView from "@/pages/Iter/views/IterAdminView";
import IterEducationalView from "@/pages/Iter/views/IterEducationalView";
import IterQuestionsView from "@/pages/Iter/views/IterQuestionsView";
import IterPriceView from "@/pages/Iter/views/IterPriceView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },


  {
    path: "/iter",
    name: "iter",
    component: IterView,
    alias: "/iter",
    props: true,
    /* children: [

        ],*/
  },
  {
    path: "/iter_student",
    name: "iter_student",
    component: IterStudentView,
    props: true,
    
  },
  {
    path: "/iter_teacher",
    name: "iter_teacher",
    component: IterTeacherView,
    props: true,
  },
  {
    path: "/iter_admin",
    name: "iter_admin",
    component: IterAdminView,
    props: true,
  },
  {
    path: "/iter_educational",
    name: "iter_educational",
    component: IterEducationalView,
    props: true,
  },
  {
    path: "/iter_questions",
    name: "iter_questions",
    component: IterQuestionsView,
    props: true,
  },
  {
    path: "/iter_price",
    name: "iter_price",
    component: IterPriceView,
    props: true,
  },

  {
    path: "/",
    name: "technical",
    alias: "/technical",
    component: TechnicalView,
  },
  {
    path: "/jobs",
    name: "jobs",
    alias: "/jobs",
    component: JobView,
  },
  {
    path: "/questions",
    name: "questions",
    alias: "/questions",
    component: CommonQuestionsView,
  },
  {
    path: "/news",
    name: "news",
    alias: "/news",
    component: NewsView,
  },
  {
    path: "/iter_learning",
    name: "iter_learning",
    alias: "/iter_learning",
    component: IterLearningView,
  },
  {
    
    path: "/events",
    name: "events",
    alias: "/events",
    component: EventsView,
  },
  {
    path: "/engz",
    name: "engz",
    alias: "/engz",
    component: EngzView,
  },

  {
    path: "/branches",
    name: "branches",
    alias: "/branches",
    component: BranchesView,
  },
  {
    path: "/b2bstore",
    name: "b2bstore",
    alias: "/b2bstore",
    component: B2BStoreView,
  },
  {
    path: "/services",
    name: "services",
    // alias: "/services",
    component: ServicesView,
  },
  {
    path: "/contact",
    name: "contact",
    alias: "/contact",
    component: ContactView,
  },
  {
    path: "/about",
    name: "about",
    alias: "/about",
    component: AboutView,
  },
  {
    path: "/agents",
    name: "agents",
    alias: "/agents",
    component: AgentsView,
    
  },
  // ... other paths ...

    {
    path: '/:catchAll(.*)*',
    name: "not-found",
    component:ErrorView
  },
];


export default routes;
