<template>
  <section id="work" class="work">
    <div class="myContainer">
      <div class="row">
        <div class="col-12">
          <!-- Section Heading Area -->
          <div class="section-heading text-center"   data-aos="fade-up">
            <h2 class="title-workItr">How chatloop Work</h2>

            <p class="mt-3">Lorem ipsum dolor sit amet, consectetur Proin gravida nibh vel velit auctor aliquet.</p>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 text-center mb-3"  data-aos="fade-up">
          <div class="process-box">
            <img src="@/assets/images/work-img/download-app.png" />
            <h3>Download app</h3>
            <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias non nulla placeat, odio </p>
            <img src="@/assets/images/work-img/download-app.png" class="bg-icon" />
          </div>
        </div>

        <div class="col-lg-4 text-center mb-3"  data-aos="fade-up">
          <div class="process-box">
            <img src="@/assets/images/work-img/conect-people.png" />
            <h3>Connect people</h3>
            <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias non nulla placeat, odio </p>
            <img src="@/assets/images/work-img/conect-people.png" class="bg-icon"/>
          </div>
        </div>

        <div class="col-lg-4 text-center mb-3"  data-aos="fade-up">
          <div class="process-box">
            <img src="@/assets/images/work-img/enjoy-app.png" />
            <h3>Enjoy app</h3>
            <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias non nulla placeat, odio </p>
            <img src="@/assets/images/work-img/enjoy-app.png" class="bg-icon" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>

</script>

<style scoped>
.work{
  padding-bottom: 50px;
}
.title-workItr {
  text-align: center;
  padding-bottom: 35px;
  position: relative;
  display: inline-block;
  font-weight: 300;
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;

}

.title-workItr::before {
  content: "";
  background: red;
  width: 70px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  margin: auto;
}

.title-workItr:after {
  content: "";
  background: var(--main-color);
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}
.process-box{box-shadow:0 1px 5px 3px #f9f9f9;background:#fff;border:1px solid rgba(89,96,130,.1);padding:30px 25px 25px}
.process-box:hover .bg-icon{transform:scale(2.5) rotate(5deg);transition:all .3s ease}
.process-box .bg-icon{opacity:.05;transform:scale(2.5) rotate(15deg);position:absolute;left:80px;bottom:60px;transition:all .3s ease}
.process-box p{margin-bottom:0}
.process-box img{height:60px}
.process-box h3{margin-top:30px}
.team-hover{border:1px solid #5f57ea;border-radius:5px;padding:15px}
.hover-text h3{margin-top:0}

</style>