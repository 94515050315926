
<template>
  <div id="app">
    <!-- Preloader -->
    <div id="preloader">
      <div class="ozient-folding-cube">
        <div class="ozient-cube1 ozient-cube"></div>
        <div class="ozient-cube2 ozient-cube"></div>
        <div class="ozient-cube4 ozient-cube"></div>
        <div class="ozient-cube3 ozient-cube"></div>
      </div>
    </div>

    <div id="home" class="main-wrapper">
      <AppHeader />
      <router-view />
      <FooterApp />
      <ProgressWrap />
    </div>
  </div>
</template>

<script setup>
import FooterApp from "@/components/layout/FooterApp";
import AppHeader from "@/components/layout/AppHeader";
import ProgressWrap from "@/components/sections/ProgressWrap";

import { useStore } from "vuex";
// import { watch } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const store = useStore();

if (localStorage.getItem("isDark")) {
  store.dispatch("setIsDarkAction", localStorage.getItem("isDark") === "true");
  localStorage.getItem("isDark") === "true"
    ? document.documentElement.setAttribute("class", "is-dark")
    : document.documentElement.removeAttribute("class");
} else {
  localStorage.setItem("isDark", false);
  store.dispatch("setIsDarkAction", false);
}
if (locale.value === "ar") {
  document.documentElement.setAttribute("lang", localStorage.getItem("lang"));
  document.documentElement.setAttribute("dir", "rtl");
} else {
  document.documentElement.setAttribute("lang", locale.value);
}

// :: 9.0 Preloader Active code
window.addEventListener("load", function () {
  document.querySelector("#preloader").remove();
});
</script>

<style>
html:lang(ar)* {
  text-align: right;
  direction: rtl;
}
/* .container:not(.is-max-desktop):not(.is-max-widescreen) {
  max-width: 1150px !important;
} */
</style>
