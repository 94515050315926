<template>
  <section class="banner_area " :style="cssProps">
    <div class="myContainer">
      <div class="banner_content">
        <h2>{{route.name}}</h2>
        <a href="/">Home</a>
        <span>&nbsp;/&nbsp;</span>
        <a :href="route.path">{{route.name}}</a>
      </div>
    </div>
  </section>

</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
    const cssProps=ref( {
        background: `url(${require('@/assets/images/blog-img/bg1.jpg')}) `
      })
    const route= useRoute()
    
console.log(route.name);
</script>

<style >
/* Banner area css
============================================================================================ */
.banner_area {
/* background: url(@/assets/images/blog-img/bg1.jpg) no-repeat scroll center center; */
  background-size: cover;
  min-height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner_area .banner_content {
  text-align: center;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  left: 0px;
}

.banner_area .banner_content h2 {
  text-transform: uppercase;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 400;
  padding-bottom: 15px;
}

.banner_area .banner_content a {
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

.banner_area .banner_content a:hover {
  color: var(--main-color);
}
.banner_area .banner_content span {
  color: #fff;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

/* .banner_area.purple_banner { */
/*background: url(public/images/banner/banner-bg-purple.jpg) no-repeat scroll center center;*/
/* } */

</style>