<template>
  <section id="how-it-works">
    <div class="myContainer">
      <div class="row">
        <div class="col-12">
          <!-- Section Heading Area -->
          <div class="section-heading text-center "   data-aos="fade-up">
            <h2 >How it Works ?</h2>
            <div class="line-title-center "></div>
            <p >Lorem ipsum dolor sit amet, consectetur Proin gravida nibh vel velit auctor aliquet.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-4 col-md-6"   data-aos="fade-up">
          <div class="how-it-single">
            <h4 class="text-dark-grey"><i class="icon-screen-smartphone"></i> Fully responsive </h4>
            <p class="text-dark-grey">We build pretty complex tools and this allows us to take designs and turn them into functional quickly and easily.</p>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6"   data-aos="fade-up">
          <div class="how-it-single">
            <h4 class="text-dark-grey"><i class="icon-equalizer icons"></i> Awesome Design</h4>
            <p class="text-dark-grey">We build pretty complex tools and this allows us to take designs and turn them into functional quickly and easily.</p>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6"   data-aos="fade-up">
          <div class="how-it-single">
            <h4 class="text-dark-grey"><i class="icon-support icons"></i> Support 24/7</h4>
            <p class="text-dark-grey">We build pretty complex tools and this allows us to take designs and turn them into functional quickly and easily.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script setup>

</script>

<style scoped>
#how-it-works{
  background-color: #fafafa;
}
.how-it-single {
  padding: 40px;
  margin-bottom: 30px;
  -webkit-transition-duration: 800ms;
  -o-transition-duration: 800ms;
  transition-duration: 800ms;
}

.how-it-single:hover {
  -webkit-box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
  box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
}

.how-it-single p {
  margin-bottom: 0;
}

.how-it-single > h4 {
  color: #636d75;
  margin-bottom: 15px;
}
.how-it-single h4 i {
  float: left;
  margin-right: 8px;
  font-size: 30px;
  color: #43cea2;
}
.how-it-single h4 i:lang(ar) {
  float: right;
  margin-left: 8px;
  font-size: 30px;
  color: #43cea2;
}


.data>h3{
  color: var(--main-color3) !important;
}
.data>p{
  color: var(--main-color2);
}
.section-heading>h2{color: var(--main-color) !important;}
.section-heading>p{color: var(--main-color2) !important;}
#how-it-works{background-color:var(--section-color2)}
</style>