<template>
  <section class="laptop-section" >
    <div class="container ">
      <div class="row">
        <div class="col-md-6 pt-50 ">
          <h1 class="display-4 mb-3 bold">Iter For Admin</h1>
          <p class="mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>
        </div>
        <div class="col-md-6">
          <div class="laptop wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.5s">
            <div class="upper">
              <img  src="@/assets/images/dashboard.jpg">
              <a  class="video_btn glightbox" ><i class="icon-control-play" aria-hidden="true"></i></a>

            </div>
            <div class="lower"></div>
          </div>
        </div>


      </div>

    </div>
  </section>

</template>

<script setup>
import  "glightbox/dist/css/glightbox.css";
import  "glightbox/dist/js/glightbox.js";
import GLightbox from 'glightbox';
import {onMounted} from 'vue'
  onMounted(()=>{
    //lightbox settings
     GLightbox({
      selector: ".glightbox",
      'touchNavigation': true,
      'href': 'https://www.youtube.com/watch?v=DbSPTfPUyoE',
      'type': 'video',
      'source': 'youtube', //vimeo, youtube or local
      'width': 900,
      'autoPlayVideos': 'true',
    });

  })
</script>

<style scoped>

.laptop-section {
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0;
  background-color: #f8f9fa!important;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.laptop{
  width: 100%;
  position: relative;
}
.laptop:before{
  background: #222;
  display: block;
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 7px;
  left: 43%;
  z-index: 1;

}
.laptop:lang(ar):before{
  background: #222;
  display: block;
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 7px;
  left: 60%;
  z-index: 1;

}
.laptop:after{
  display: block;
  content: '';
  position: absolute;
  width: 71%;
  margin-left: 2.5%;
  height: .75rem;
  box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 5rem;
}



.upper{
  width: 80%;
  height: 23rem;
  background: #3d4144;
  border: 2px solid #eaeaea;
  border-bottom: none;
  border-radius: 1.5rem 1.5rem 0 0;
  margin-left: 4%;
  box-shadow: inset 0 0 2px 2px #222;
  position: relative;
}
.upper:before{
  width: 86%;
  height: 19rem;
  background:#aaa;
  display: block;
  content: '';
  top: 2rem;
  left: 7%;
  position: absolute;
}
.upper img{
  width: 86%;
  height: 19rem;
  top: 2rem;
  left: 7%;
  position: absolute;
  z-index: 2;
}
.upper .video_btn{
  width: 80px;
  height: 80px;
  background-color: #41c9a2;
  color: #fff;
  text-align: center;
  display: block;
  font-size: 36px;
  line-height: 86px;
  padding-left: 5px;
  border-radius: 50%;
  top: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
  cursor: pointer;
}
.upper .video_btn:hover {
  background-color: #fff;
  color: #41c9a2;

}
/*
.upper:after{
  display: block;
  content: '';
  position: absolute;
  border-bottom: 23rem solid rgba(0,0,0,0.075);
  border-right: 10rem solid transparent;
  height: 0;
  width: 50%;
  border-top-left-radius: 1.5rem;
}
*/

.lower{
  width: 90%;
  height: 2rem;
  background: #eaeaea;
  border-radius: 2px;
  position: relative;
}
.lower:before{
  display: block;
  content: '';
  position: absolute;
  width: 10%;
  height: 0.8rem;
  background: #a1a1a1;
  left: 45%;
  top: 0;
  border-radius: 0 0 0.25rem 0.25rem;
}
.lower:after{
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: .75rem;
  background: #bababa;
  top: 2rem;
  border-bottom-right-radius: 7.5rem 2.5rem;
  border-bottom-left-radius: 7.5rem 2.5rem;
}


</style>