import script from "./landing-34.vue?vue&type=script&setup=true&lang=ts"
export * from "./landing-34.vue?vue&type=script&setup=true&lang=ts"

import "./landing-34.vue?vue&type=style&index=0&id=6f678b12&lang=css"
/* custom blocks */
import block0 from "./landing-34.vue?vue&type=custom&index=0&blockType=route&lang=yaml"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__